<div class="footer">
    <div class="footer__menu">
        <!-- <div class="menu nav">
            <a href="/main/pathways-and-programs">Pathways</a>
            <a href="/main/pathways-and-programs">Programs</a>
            <a href="/main/content-filter">Courses</a>
            <a href="main/content-filter">Subjects</a>
            <a href="/main/plans">Plans</a>
            <a href="/auth/signup">Tutors</a>
            <a href="/main/gallery">Gallery</a>
        </div>
        <div class="menu community">
            <a href="/main">Home</a>
            <a href="/main/community">Community</a>
            <a href="/main/goals">Goals</a>
            <a href="/main/blogs">Blog</a>
            <a href="/main/events">Event</a>
            <a href="/main/research-and-development">Research & Development</a>
        </div>
        <div class="menu info">
            <a href="/main/terms-condition">Terms & Conditions</a>
            <a href="/main/privacy-policy">Privacy & Policy</a>
            <a href="/main/cookies-policy">Cookies Policy</a>
            <a href="/main/contact-us">Contact Us</a>
            <a href="/main/about-us">About Us</a>
        </div> -->

        <div class="menu nav">
            <!-- First Column -->
            <a href="/main/pathways-and-programs">Pathways</a>
            <a href="/main/pathways-and-programs">Programs</a>
            <a href="/main/content-filter">Courses</a>
            <a href="main/content-filter">Subjects</a>
            <a href="/main/plans">Plans</a>
            <a href="/auth/signup">Tutors</a>
            <a href="/main/goals">Goals</a>
        </div>

        <div class="menu community">
            <!-- Second Column -->
            <a href="/main/community">Community</a>
            <a href="/main/gallery">Gallery</a>
            <a href="/main/blogs">Blogs</a>
            <a href="/main/events">Events</a>
            <a href="/main/research-and-development">Research & Development</a>
        </div>

        <div class="menu info">
            <!-- Third Column -->
            <a href="/main/about-us">About Us</a>
            <a href="/main/contact-us">Contact Us</a>
            <a href="/main/terms-condition">Terms & Conditions</a>
            <a href="/main/privacy-policy">Privacy Policy</a>
            <a href="/main/cookies-policy">Cookies Policy</a>
        </div>

        <div class="menu socials">
            <div>
                <img src="../../../../assets/images/icons8-facebook-50 (2).png" alt="Facebook"
                    (click)="redirectTo('https://www.facebook.com/byild.co/')" />

                <img src="./assets/images/icons8-youtube-50.png" alt="YouTube"
                    (click)="redirectTo('https://www.youtube.com/@byild-co/')" />

                <img src="../../../../assets/images/icons8-insta-50.png" alt="Instagram"
                    (click)="redirectTo('https://www.instagram.com/byild.co/')" />
            </div>
            <div>
                <img src="../../../../assets/images/icons8-tiktok-50.png" alt="TikTok"
                    (click)="redirectTo('https://www.tiktok.com/@byild.co/')" />

                <img src="../../../../assets/images/icons8-twitter-50.png" alt="X (formerly Twitter)"
                    (click)="redirectTo('https://x.com/byild_co/')" />

                <img src="../../../../assets/images/icons8-linkedin-50 (2).png" alt="LinkedIn"
                    (click)="redirectTo('https://www.linkedin.com/in/byild-co/')" />
            </div>
        </div>

    </div>

    <div class="footer__brand">
        <img src="./assets/images/white_logo.png" alt="">
        <p>BYILD® is a trademark of IDS UK</p>
    </div>
</div>