import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationPipe'
})
export class DurationPipePipe implements PipeTransform {

  // transform(minutes: number): string {
  //   if (minutes == null || isNaN(minutes)) {
  //     return '';
  //   }

  //   const hours = Math.floor(minutes / 60);
  //   const remainingMinutes = minutes % 60;

  //   return `${hours} hrs ${remainingMinutes} min`;
  // }


  transform(minutes: number): string {
    if (minutes == null || isNaN(minutes)) {
      return '';
    }

    const weeks = Math.floor(minutes / (60 * 24 * 7)); // Convert minutes to weeks
    const days = Math.floor((minutes % (60 * 24 * 7)) / (60 * 24)); // Remaining minutes to days
    const hours = Math.floor((minutes % (60 * 24)) / 60); // Remaining minutes to hours
    const remainingMinutes = minutes % 60; // Remaining minutes

    let result = '';
    if (weeks > 0) {
      result += `${weeks} wk${weeks > 1 ? 's' : ''} `;
    }
    if (days > 0) {
      result += `${days} day${days > 1 ? 's' : ''} `;
    }
    if (hours > 0) {
      result += `${hours} hr${hours > 1 ? 's' : ''} `;
    }
    if (remainingMinutes > 0) {
      result += `${remainingMinutes} min`;
    }

    return result.trim();
  }


}
