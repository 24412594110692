<div class="program-info">
    <div class="program-modal-header">
        <img [src]="program.image" alt="">
        <div class="close-btn" (click)="close()">
            <img src="../../../../assets/images/close-modal.png" alt="close-btn">
        </div>
    </div>
    <div class="program-modal-body">
        <div class="modal-section">
            <div class="program-introduction">
                <div class="program-info-title">
                    <h2>{{program.programName}}</h2>
                    <p>{{program.programDescription}}</p>
                </div>
                <!-- <div class="program-tutor">
                    <img [src]="program.instructor.pictureLink" alt="">
                    <div class="tutor-name">{{program.instructor.firstName}}</div>
                </div> -->
            </div>
            <div class="social-icons">
                <!-- <div class="social-containers">
                    <img src="../../../../assets/images/signal.png" alt="">
                    <div class="labels">
                        <label class="level" for="text">Level</label>
                        <label for="number">{{program.rating || 0}}</label>
                    </div>
                </div> -->
                <!-- <div class="social-containers">
                    <img src="../../../../assets/images/contacts.png" alt="">
                    <div class="labels">
                        <label class="level" for="text">Enrolled Students</label>
                        <label for="number">{{program.courses|| 0}}</label>
                    </div>
                </div> -->
                <div class="social-containers">
                    <img src="../../../../assets/images/rename.png" alt="">
                    <div class="labels">
                        <label class="level" for="text">Duration</label>
                        <label for="number">{{program.duration}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>