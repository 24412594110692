import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class LecturesService {

  constructor(private http: HttpClient) { }


  getdocuments(payload: any): Observable<any> {
    return this.http.get(environment.apiUrl + `/document/all/${payload}`)
  }
  getdocumentsEnrolled(payload: any): Observable<any> {
    return this.http.get(environment.apiUrl + `/section/enrolled/${payload}/sections`)
  }
  // api/v1/section/{course-id}/sections-with-documents
  getSectionWithDocuments(payload: any): any {
    return this.http.get(environment.apiUrl + `/section/${payload}/sections-with-documents`)
  }

  getDocumentSections(payload: any): Observable<any> {
    return this.http.get(environment.apiUrl + `/section/${payload}/sections`)
  }

  getDocumentSectionById(payload: any): Observable<any> {
    return this.http.get(environment.apiUrl + `/document/${payload}/documents`)
  }



  completeSession(payload: any): Observable<any> {
    return this.http.put(environment.apiUrl + `/completion/document/${payload}`, {})
  }


  // completion/program/course/
  completeProgramCourse(payload: any): any {
    return this.http.post(environment.apiUrl + `/completion/program/course/${payload}`, {})
  }
  getQuizbyId(payload: any): Observable<any> {
    return this.http.get(environment.apiUrl + `/quiz/${payload}`)
  }

  submitQuiz(id: any, payload: any): Observable<any> {
    return this.http.post(environment.apiUrl + `/quiz/${id}/submit`, payload)
  }

  getQuizResult(id: any): Observable<any> {
    return this.http.get(environment.apiUrl + `/quiz/${id}/result`)
  }


  getSurvey(courseId: any, pageNo: any, pageSize: any): Observable<any> {
    return this.http.get(environment.apiUrl + `/survey/course/${courseId}/for-learner?pageNo=${pageNo}&pageSize=${pageSize}`)
  }
  // /api/v1/survey/{surveyId}/for-learner/submit
  submitSurvey(payload: any, surveyId: any): Observable<any> {
    return this.http.post(environment.apiUrl + `/survey/${surveyId}/for-learner/submit`, payload)
  }

}
