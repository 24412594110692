import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCategoryService } from './add-category.service';
import { lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent {

  @Input() courses: any;
  categoryName: string = ''; // To store the category name
  isActive: boolean = false; // To store the category status
  selectedFile: File | null = null; // To store the selected file
  imageUrl: string | null = null; // To preview the image
  subcategories: any[] = ['']; // To store the sub category name
  spinner: boolean = false;

  constructor(private activeModal: NgbActiveModal, private service: AddCategoryService) { }

  close(): void {
    this.activeModal.close();
  }

  // onCategoryNameChange(event: Event): void {
  //   this.categoryName = (event.target as HTMLInputElement).value;
  // }

  onSubcategoryNameChange(event: Event, index: number): void {
    this.subcategories[index] = (event.target as HTMLInputElement).value;
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      console.log(file);

      this.selectedFile = file; // Store the file object
      console.log(this.selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }


  onStatusChange(event: Event): void {
    this.isActive = (event.target as HTMLInputElement).checked;
  }

  async submit(): Promise<void> {
    console.log(1)
    // event.preventDefault(); // Prevent form from submitting the default way

    if (this.categoryName && this.selectedFile) {
      this.spinner = true
      const formData = new FormData();
      formData.append('categoryName', this.categoryName);
      // formData.append('isActive', this.isActive.toString());
      formData.append('thumbnail', this.selectedFile);
      console.log('Category data saved:', formData);
      this.subcategories.forEach((subcategory, index) => {
        formData.append(`subCategories[${index}]`, subcategory);
      });
      try {
        this.spinner = true
        const res = await lastValueFrom(this.service.createCategory(formData));
        // Handle success
        if (res.status === 201) {
          this.spinner = false
          Swal.fire({
            title: 'Success',
            text: 'Category Added successfully',
            icon: 'success',
            confirmButtonColor: '#0075AB',
          })

          window.location.reload();
          // Handle successful response
        } else {
          this.spinner = false
          Swal.fire({
            title: 'Error',
            text: 'Something went wrong',
            icon: 'error',
            confirmButtonColor: '#0075AB',
          })


        }
      } catch (error) {
        this.spinner = false
        // Handle error
      }
    }
  }
  trackByFn(index: number, item: any): number {
    return index; // or return item.id if you have unique identifiers
  }


  // Method to add a new subcategory input
  addSubcategory() {
    console.log(2)
    this.subcategories.push('');
  }

  // Method to remove a subcategory input
  removeSubcategory(index: number) {
    console.log(3)
    if (this.subcategories.length > 1) {
      this.subcategories.splice(index, 1);
    }
  }

  updateSubcategory(value: string, index: number): void {
    console.log(4)
    this.subcategories[index] = value;
  }

}
