import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(private http: HttpClient) { }

  getCommentsByDocument(payload: any, id: any): any {
    return this.http.get(environment.apiUrl + `/comments/by-document/${id}?pageNo=${payload.page}&pageSize=${payload.size}`)
  }

  addCommentAgainstDocument(payload: any, id: any): any {
    return this.http.post(environment.apiUrl + `/comments/document/${id}`, payload)
  }
  pinUnpinComment(payload: any, state: string): any {
    return this.http.patch(environment.apiUrl + `/comments/${payload}/${state}`, {})
  }
}
