<div class="custom__modal">
    <div class="custom__header">
        <div class="heading">
            <img src="../../../../assets/images/sidebar/user.png" alt="">
            <h3>{{updateId ? 'Update' : 'Add'}} User</h3>
        </div>
        <div class="close__btn" (click)="close()"></div>
    </div>
    <div class="custom__body">
        <form [formGroup]="userForm" autocomplete="off">
            <div class="form__body">
                <div class="form__divider">
                    <div class="form__fields">
                        <div class="field__inline">
                            <label for="orgName">User Name</label>

                            <div class="form-floating">
                                <div>
                                    <input type="text" class="form-control" formControlName="name" name="orgName"
                                        id="orgName" placeholder="John Snow" required autocomplete="off">
                                </div>
                                <div class="error-msg"
                                    *ngIf="userForm.get('name')?.hasError('required') && userForm.get('name')?.touched">
                                    User Name is required.
                                </div>
                                <div class="error-msg"
                                    *ngIf="userForm.get('name')?.hasError('pattern') && userForm.get('name')?.touched">
                                    User Name can only have alphabets.
                                </div>
                            </div>

                        </div>
                        <div class="field__inline" *ngIf="updateId ? false :  true">
                            <label for="email">Email Address</label>

                            <div class="form-floating">
                                <div>
                                    <input type="text" class="form-control" formControlName="email" name="email"
                                        id="email" placeholder="demo@gmail.com" required autocomplete="off">
                                </div>
                                <div class="error-msg"
                                    *ngIf="userForm.get('email')?.hasError('required') && userForm.get('email')?.touched">
                                    Email is required.
                                </div>
                                <div class="error-msg"
                                    *ngIf="userForm.get('email')?.hasError('email') && userForm.get('email')?.touched">
                                    Please enter a valid email address.
                                </div>
                            </div>

                        </div>
                        <div class="field__inline">
                            <label for="number">Contact Number</label>

                            <div class="form-floating">
                                <div>
                                    <!-- <input type="text" class="form-control" formControlName="contactNumber"
                                        name="number" id="number" placeholder="32 456 2132135" required
                                        autocomplete="off" appNumericOnly> -->
                                    <!-- <ng-select class="w-100" name="frequency">
                                        <ng-option value="1">Yes</ng-option>
                                        <ng-option value="0">No</ng-option>
                                    </ng-select> -->
                                    <!-- <app-tel-number (valueChange)="updateForm($event)" (touched)="touched()"
                                        #tel></app-tel-number> -->
                                </div>
                                <div class="error-msg"
                                    *ngIf="userForm.get('contactNumber')?.hasError('required') && userForm.get('contactNumber')?.touched">
                                    Contact Number is required.
                                </div>
                                <div class="error-msg"
                                    *ngIf="userForm.get('contactNumber')?.hasError('pattern') && userForm.get('contactNumber')?.touched">
                                    Please enter a valid Contact Number.
                                </div>
                            </div>

                        </div>
                        <div class="field__inline">
                            <label for="designation">Department</label>

                            <div class="form-floating">
                                <div>
                                    <input type="text" class="form-control" formControlName="department"
                                        name="department" id="department" placeholder="HR" required autocomplete="off">
                                </div>
                                <!-- <div class="error-msg"
                                    *ngIf="userForm.get('department')?.hasError('required') && userForm.get('department')?.touched">
                                    Department is required.
                                </div> -->

                                <div class="error-msg"
                                    *ngIf="userForm.get('department')?.hasError('required') && userForm.get('department')?.touched">
                                    Department is required.
                                </div>
                                <div class="error-msg"
                                    *ngIf="userForm.get('department')?.hasError('pattern') && userForm.get('department')?.touched">
                                    Department can only have alphabets.
                                </div>
                            </div>

                        </div>
                        <div class="field__inline">
                            <label for="designation">Designation</label>

                            <div class="form-floating">
                                <div>
                                    <input type="text" class="form-control" formControlName="designation"
                                        name="designation" id="designation" placeholder="Manager" required
                                        autocomplete="off">
                                </div>
                                <!-- <div class="error-msg"
                                    *ngIf="userForm.get('designation')?.hasError('required') && userForm.get('designation')?.touched">
                                    Designation is required.
                                </div> -->

                                <div class="error-msg"
                                    *ngIf="userForm.get('designation')?.hasError('required') && userForm.get('designation')?.touched">
                                    Designation is required.
                                </div>
                                <div class="error-msg"
                                    *ngIf="userForm.get('designation')?.hasError('pattern') && userForm.get('designation')?.touched">
                                    Designation can only have alphabets.
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="form__image">
                        <div class="field__inline">
                            <label for="roleId">Role</label>

                            <div class="form-floating">
                                <div>
                                    <select name="roleId" class="form-control form-select" placeholder="Role"
                                        formControlName="roleId" id="roleId">
                                        <ng-container *ngFor="let i of Roles">
                                            <option [value]="i.id">{{i.name}}</option>
                                        </ng-container>
                                    </select>

                                    <!-- <mat-form-field>
                                        <mat-select name="roleId" placeholder="Role" formControlName="roleId"
                                            id="roleId" required autocomplete="off">
                                            <mat-option *ngFor="let item of Roles" [value]="item.id">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field> -->

                                    <div class="error-msg"
                                        *ngIf="userForm.get('roleId')?.hasError('required') && userForm.get('roleId')?.touched">
                                        Role is required.
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="field__inline field__img">
                            <label for="CompanyLogo">Profile Picture</label>
                            <div class="image-drop">
                                <input type="file" (change)="onFileSelected($event, 'logo')" accept="image/*" hidden
                                    #logo>
                                <div *ngIf="!imageUrl" class="container" (dragover)="onDragOver($event)"
                                    (drop)="onDrop($event)">
                                    <img class="upload-icon"
                                        src="../../../../assets/images/utilities/gallery-add.png" />

                                    <p class="upload-text">Drag or Drop image here</p>
                                    <p class="upload-text">or</p>
                                    <button class="btn btn-outline-primary" (click)="logo.click()">Browse
                                        Files</button>
                                </div>

                                <img class="preview" *ngIf="imageUrl" (click)="logo.click()" [src]="imageUrl"
                                    alt="Uploaded Image">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal__btn">
                <button class="btn btn-secondary" (click)="clearAll()">Clear All</button>
                <button class="btn btn-primary" (click)="submit()" [disabled]="userForm.invalid || spinner">
                    <span *ngIf="spinner" class="spinner-border spinner-border-sm me-2" role="status "
                        aria-hidden="true"></span>
                    {{ updateId ? 'Update' : 'Send Invite'}}
                </button>
            </div>
        </form>
    </div>
</div>