import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class AddUserService {
  constructor(private http: HttpClient) { }

  addUser(payload: any): Observable<any> {
    return this.http.post(environment.apiUrl + `/users/invite`, payload)
  }
  updateUser(payload: any, updateId: string): Observable<any> {
    return this.http.put(environment.apiUrl + `/api/v1/users/${updateId}`, payload)
  }
  getRoles(): Observable<any> {
    return this.http.get(environment.apiUrl + '/roles')
  }
}
