import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-assign-course',
  templateUrl: './assign-course.component.html',
  styleUrls: ['./assign-course.component.scss']
})
export class AssignCourseComponent {
  courses: any[] = []
  constructor(private activeModal: NgbActiveModal) { }
  close() {
    this.activeModal.close()
    
    

  }
  save() {
    let list = this.courses.filter(course => course.active)
    this.activeModal.close(list)
  }
  clearAll() {
    this.courses.forEach(course => course.active = false)
  }
}
