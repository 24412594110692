<div class="course-info">
    <div class="course-modal-header">
        <img [src]="course.image" alt="">
        <div class="close-btn" (click)="close()">
            <img src="../../../../assets/images/close-modal.png" alt="close-btn">
        </div>
    </div>
    <div class="course-modal-body">
        <div class="modal-section">
            <div class="course-introduction">
                <div class="course-info-title">
                    <h2>{{course.courseName}}</h2>
                    <p [innerHTML]="course.courseDescription"></p>
                </div>
                <div class="course-tutor">
                    <img [src]="course.instructor.pictureLink"
                        (error)="course.instructor.pictureLink = '../../../../assets/images/3.png'" alt="">
                    <div class="tutor-name">{{course.instructor.firstName}}</div>
                </div>
            </div>
            <div class="social-icons">
                <div class="social-containers">
                    <img src="../../../../assets/images/signal.png" alt="">
                    <div class="labels">
                        <label class="level" for="text">Level</label>
                        <label for="number">{{course.level}}</label>
                    </div>
                </div>
                <!-- <div class="social-containers">
                    <img src="../../../../assets/images/contacts.png" alt="">
                    <div class="labels">
                        <label class="level" for="text">Enrolled Students</label>
                        <label for="number">{{course.enrollment || 0}}</label>
                    </div>
                </div> -->
                <div class="social-containers">
                    <img src="../../../../assets/images/rename.png" alt="">
                    <div class="labels">
                        <label class="level" for="text">Duration</label>
                        <label for="number">{{course.duration}}</label>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="courseDetails.length <= 0 && !loader"
            class="name d-flex justify-content-center  align-items-center">Course details not available</div>
        <div *ngIf="loader" class="name d-flex justify-content-center  align-items-center"><span
                class="spinner-border spinner-border-sm me-2" role="status " aria-hidden="true"></span> Loading...</div>


        <div class="lecture" *ngIf="showContent">
            <div class="main-page">

                <div class=" main">

                    <div class="flex-item sidebar-flex">
                        <div class="sidebar">
                            <ul class="heading-list">
                                <li *ngFor="let item of courseDetails" [class.selected]="item === selectedItem"
                                    (click)="selectItem(item) " class="list-group-item">
                                    <div class="box">
                                        <div class="circle"></div>
                                    </div>

                                    <P>

                                        <b>{{item.documentName|titlecase}} </b>
                                    </P>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="flex-item video-flex">
                        <div class="flex-video-container">
                            <div class="video-main">
                                <div class="video-container">
                                    <div class="video-heading">
                                        <h1>{{selectedItemToShow?.documentName |titlecase}}</h1>
                                    </div>

                                    <iframe *ngIf="selectedItemToShow.documentType==='VIDEO'"
                                        [src]="selectedItemToShow.url" class="lms-video" frameborder="0"
                                        allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                                        style="width: 100%; height: auto; aspect-ratio: 16/9;">
                                    </iframe>


                                    <img [src]="selectedItemToShow.documentLink" alt=""
                                        *ngIf="selectedItemToShow.documentType === 'IMAGE'"
                                        style="width: 100%; height: auto; aspect-ratio: 16/9;">


                                    <a [href]="selectedItemToShow.documentLink" target="_blank"
                                        rel="noopener noreferrer" *ngIf="selectedItemToShow.documentType === 'PDF'">
                                        <div class="image-preview-file">

                                            <div *ngIf="selectedItemToShow.documentLink.includes('pdf')" class="w-100">
                                                <ngx-doc-viewer [url]="selectedItemToShow.documentLink " viewer="google"
                                                    style="width:100%;height:20rem; margin-top: 2px; border-radius: 10px;"></ngx-doc-viewer>
                                            </div>

                                            <div *ngIf="selectedItemToShow.documentLink.includes('ppt')" class="w-100">
                                                <ngx-doc-viewer [url]="selectedItemToShow.documentLink " viewer="office"
                                                    style="width:100%;height:20rem; margin-top: 2px; border-radius: 10px;"></ngx-doc-viewer>
                                            </div>
                                        </div>
                                    </a>






                                    <div class="video-nav">
                                        <div class="arrows d-flex justify-content-end">
                                            <button class="btn bg-hover  equal-size d-flex justify-content-end "
                                                [disabled]="isPreviousDisabled()" (click)="previous()">
                                                <img src="../../../assets/images/Frame (3).png" /> Previous
                                            </button>
                                            <button class="btn bg-hover equal-size d-flex justify-content-end "
                                                [disabled]="isNextDisabled()" (click)="next()">Next<img
                                                    src="../../../assets/images/Frame (1).png" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
            <div class="main-desc-container ">
                <div class="main-description">
                    <h2>Description </h2>
                    <p class="description">
                        <span [innerHTML]="selectedItemToShow.documentDescription"></span>
                        <br />
                        <span [innerHTML]="selectedItemToShow.sectionResponse.sectionDescription"></span>
                        <br /><br />
                    </p>
                </div>
            </div>
        </div>


    </div>
</div>