import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent {
  showBanner = false;

  constructor(
    private cookieService: CookieService,
    private analytics: AngularFireAnalytics,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.showBanner = !this.cookieService.check('gdpr_consent');
  }

  acceptCookies(): void {

    this.analytics.logEvent('Cookies_consent', { button_name: 'Accept' });
    this.cookieService.set('gdpr_consent', 'accepted', 365);  // Set cookie for 1 year
    this.showBanner = false;
  }

  declineCookies(): void {
    this.analytics.logEvent('Cookies_consent', { button_name: 'Declined' });
    this.cookieService.set('gdpr_consent', 'declined', 365);  // Set cookie for 1 year
    this.showBanner = false;
  }

  redirect() {
    this.router.navigate(['/main/cookies-policy'])
  }
}