import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { TutorInfoService } from './tutor-info.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-tutor-info',
  templateUrl: './tutor-info.component.html',
  styleUrls: ['./tutor-info.component.scss']
})
export class TutorInfoComponent {
  tutor!: any;
  courses: any[] = []
  loader: boolean = false;

  CoursesName = [
    {
      name: "Advance Data Structures and Algorithms  Data Structures and Algorithms Data Structures and Algorithms"
    },
    {
      name: "Advance Data Structures and Algorithms"
    },
    {
      name: "Advance Data Structures and Algorithms"
    },
    {
      name: "Advance Data Structures and Algorithms"
    },
    {
      name: "Advance Data Structures and Algorithms"
    },


  ]
  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private service: TutorInfoService,
    private ngxspinner: NgxSpinnerService
  ) { }

  coursesList: any[] = []
  course = '';

  metricsData: any;
  getTutorMetrics() {
    this.ngxspinner.show()

    lastValueFrom(this.service.getTutorMetrics(this.tutor.id)).then((res) => {
      if (res) {
        this.ngxspinner.hide()
        console.log(res)
        this.metricsData = res.data || {};
      } else {
        this.ngxspinner.hide()
      }

    }, (error) => {
      this.ngxspinner.hide()

    }).catch((err) => {
      this.ngxspinner.hide()
    })
  }

  getCourseWithLo() {
    let id = this.tutor.id
    lastValueFrom(this.service.getCourseWithLo(id)).then(x => {
      this.courses = x.data
    })
  }

  selectedCourse: any;
  showLearningOutcomes() {
    this.ngxspinner.show()
    this.selectedCourse = this.courses.find(x => x.courseId === this.course)
    console.log(this.selectedCourse)
    this.selectedCourse.learningOutcomes.forEach((element: any) => {
      element.show = false;
      lastValueFrom(this.service.getLearningOutcomeScore(element.id, JSON.parse(localStorage.getItem('user')!).id)).then(x => {
        this.ngxspinner.hide();

        element.score = x.data.sectionPerformances;

        element.chartSeries = [
          {
            name: "Excellent",
            data: element.score.map((section: any) => section.excellentLearnersCount)
          },
          {
            name: "Good",
            data: element.score.map((section: any) => section.goodLearnersCount)
          },
          {
            name: "Average",
            data: element.score.map((section: any) => section.averageLearnersCount)
          },
          {
            name: "Weak",
            data: element.score.map((section: any) => section.weakLearnersCount)
          }
        ];

        // Updated chart options for bar chart
        element.chartOptions = {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          },
          fill: {
            colors: ['#0075ab', '#0075bb', '#0075cb', '#0075db'] // Colors for Excellent, Good, Average, Weak
          }
        };

        // Set xAxis categories based on section IDs
        element.xAxisOptions = {
          categories: element.score.map((_: any, index: number) => index + 1), // X-axis categories
          labels: {
            rotate: 0,
            style: {
              fontSize: '12px',
              fontFamily: 'Barlow-600',
            },
            formatter: function (value: string) {
              return value.length > 10 ? value.substring(0, 10) + '...' : value;
            }
          },
          title: {
            text: 'Section ID'
          }
        };

        // Set yAxis options with learning outcomes categories
        element.yAxisOptions = {
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: 'Barlow-600',
            },
            formatter: (value: any) => {
              return Math.floor(value);  // Ensures that only integer values are displayed
            }
          },
          min: 0, // Minimum value for y-axis
          tickAmount: 5,
          title: {
            text: 'Learner Count',
            style: {
              fontFamily: 'Barlow-600',
            }
          }
        };

        element.show = true;
      }).catch((err: any) => {
        this.ngxspinner.hide();
      });

    })
  }

  ngOnInit() {
    this.loader = true

    lastValueFrom(this.service.getTutorProfile(this.tutor.id)).then(
      (res) => {
        if (res.status == '200') {
          this.courses = res.data.courseList
          this.loader = false
        } else {
          this.loader = false
        }
      }, (err) => {
        this.loader = false
      })

    this.getCourseWithLo()
    this.getTutorMetrics()
  }

  close() {
    this.activeModal.close()
  }

  yearlyCourseRating: any;
  getYearlyCourseRating() {

    lastValueFrom(this.service.getYearlyCourseRating(this.course)).then(
      res => {
        console.log(res)
        this.yearlyCourseRating = {
          series: [{
            name: "Count",
            data: res.data.map((item: any) => item.rating)
          }],
          chart: {
            type: "bar",
            height: 350,
            zoom: {
              enabled: false
            }
          },
          plotOptions: {
            // bar: {
            //   borderRadius: 20,
            //   columnWidth: 50,
            //   dataLabels: {
            //     position: 'center'
            //   }
            // }

            bar: {
              borderRadius: 0,
              columnWidth: '50rem',
              dataLabels: {
                position: 'center'
              }
            }
          },
          xaxis: {
            categories: res.data.map((item: any) => item.year),
            labels: {
              rotate: -0,
              style: {
                fontSize: '12px',
                fontFamily: 'Barlow-600',
              },

              formatter: function (value: string) {
                return value.length > 10 ? value.substring(0, 10) + '...' : value;
              }

            }

          },
          title: {
            text: "Course Rating Throughout the Years",
            style: {
              fontFamily: 'Barlow-600',

            }
          },
          fill: {
            colors: ['#0075ab']
          },
        };
      }
    )
  }
}
