<div class="home">
    <div class="banner"></div>
    <div class="sections">

        <section class="content-container">
            <h2 class="fw-bold">{{article.heading}}</h2>
            <h3>{{article.subHeading}}</h3>
            <h6 class="fw-bold">{{article.date | date: 'MMMM d, y'}}</h6>
            <div class="tags_list">
                <div class="tag" *ngFor="let tag of article.tags">
                    {{tag}}
                </div>
            </div>
            <ng-container *ngFor="let content of article.contents">
                <div *ngIf="content.contentType === 'TEXT'" [innerHTML]="content.text"></div>
                <img class="content-img" *ngIf="content.contentType === 'IMAGE'" [src]="content.imageUrl" alt="">
            </ng-container>
        </section>
    </div>
</div>