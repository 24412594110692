<div class="header" [style.background-color]="header">
    <a class="header__logo" href="/main">
        <img src="assets/images/black-logo.png" alt="Logo" />
    </a>

    <div class="header__hamburger">
        <img [src]="show ? './assets/images/close.png' : './assets/images/hamburger.png'" alt="" (click)="show = !show">
    </div>
    <ul class="header__menu" [class.showMenu]="show">
        <li class="menu__item menu__search">
            <div class="search-container">
                <select class="form-select search-type-dropdown" [(ngModel)]="type" (change)="searchValue()">
                    <option value="COURSE">Courses</option>
                    <option value="PROGRAM">Programs</option>
                    <option value="PATHWAY">Pathways</option>
                    <option value="PROFESSION">Professions</option>
                    <option value="TAGS">Tags</option>
                    <option value="SUBJECT">Subjects</option>
                </select>
                <img class="down-arrow" src=" ./assets/images/down-arrow.png" alt="">
                <input class="searchbar" type="search" placeholder="Search..." [(ngModel)]="search"
                    (ngModelChange)="onSearchInput($event)" (keydown.enter)="searchValue()">
                <span class="search-icon">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.5451 13.6626L16.9864 16.1026C17.1002 16.2205 17.1632 16.3784 17.1618 16.5422C17.1603 16.7061 17.0946 16.8629 16.9787 16.9787C16.8629 17.0946 16.7061 17.1603 16.5422 17.1618C16.3784 17.1632 16.2205 17.1002 16.1026 16.9864L13.6614 14.5451C12.0661 15.9119 10.0034 16.6077 7.90612 16.4865C5.80887 16.3652 3.8401 15.4364 2.41291 13.8949C0.985729 12.3534 0.211076 10.319 0.251507 8.21863C0.291937 6.11827 1.14431 4.11521 2.62976 2.62976C4.11521 1.14431 6.11827 0.291937 8.21863 0.251507C10.319 0.211076 12.3534 0.985729 13.8949 2.41291C15.4364 3.8401 16.3652 5.80887 16.4865 7.90612C16.6077 10.0034 15.9119 12.0661 14.5451 13.6614V13.6626ZM8.3751 15.2501C10.1985 15.2501 11.9471 14.5258 13.2365 13.2365C14.5258 11.9471 15.2501 10.1985 15.2501 8.3751C15.2501 6.55174 14.5258 4.80305 13.2365 3.51374C11.9471 2.22443 10.1985 1.5001 8.3751 1.5001C6.55174 1.5001 4.80305 2.22443 3.51374 3.51374C2.22443 4.80305 1.5001 6.55174 1.5001 8.3751C1.5001 10.1985 2.22443 11.9471 3.51374 13.2365C4.80305 14.5258 6.55174 15.2501 8.3751 15.2501Z"
                            fill="black" />
                    </svg>

                </span>
            </div>

            <div class="search__list" *ngIf="searchList.length > 0">
                <ng-container *ngIf="searchList.length > 0 && !searchSpinner">
                    <div class="search__block" *ngFor="let item of searchList" (click)="redirectSearch(item)">
                        <img [src]="item.imageLink?item.imageLink:'../../../../assets/images/profile.png'" alt="">
                        <span>{{item.name}}</span>
                    </div>
                </ng-container>
                <div class="d-flex justify-content-center p-4" *ngIf="searchSpinner">
                    <div class="name"><span class="spinner-border spinner-border-sm me-2" role="status "
                            aria-hidden="true"></span> Loading...</div>
                </div>
            </div>

        </li>
        <li class="menu__item" *ngIf="hideDiv">
            <a class="btn btn-borderless-primary signin-btn" href="/main/content-filter?hours=1">
                <span>Awesome in 60</span>
            </a>
        </li>
        <li class="menu__item">
            <a *ngIf="!user" class="btn btn-primary  signin-btn" href="/auth">
                <span>{{"Login"}}</span>
            </a>
            <button *ngIf="user" class="btn btn-primary  signin-btn" (click)="login()">
                <span>{{"Log Out"}}</span>
            </button>
        </li>
        <li *ngIf="role==='USER'|| role==='TUTOR'" class="menu__item" style="position: relative;">
            <img [src]="picture? picture:'../../../../assets/images/profile.png'" alt="Profile"
                (click)="toggleDropdown()" class="dropdown-toggle">
            <div *ngIf="isDropdownOpen" style="position: absolute; top: 118%; left: -71px; width: 100%; z-index: 1000;">
                <ul class="dropdown-menu show"
                    style="display: block; background-color: #fff; border: 1px solid #ccc; padding: 5px; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);">
                    <li style="  font-family: 'Barlow-600';"><a class="dropdown-item"
                            (click)="navigateTo('home')">Home</a></li>
                    <li style="  font-family: 'Barlow-600';"><a class="dropdown-item"
                            (click)="navigateTo('Plans')">Plans</a></li>
                    <li style="  font-family: 'Barlow-600';"><a class="dropdown-item"
                            (click)="navigateTo('ContentFilter')">Search</a></li>
                    <li style="  font-family: 'Barlow-600';" *ngIf="role==='USER'"><a class="dropdown-item"
                            (click)="navigateTo('learnerDashboard')">Dashboard</a></li>
                    <li style="  font-family: 'Barlow-600';" *ngIf="role==='TUTOR'"><a class="dropdown-item"
                            (click)="navigateTo('tutorDashboard')">Dashboard</a></li>
                </ul>
            </div>
        </li>
    </ul>
</div>