import { Component } from '@angular/core';

@Component({
  selector: 'app-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.scss']
})
export class PortalHeaderComponent {
  username: string = JSON.parse(localStorage.getItem('user')!).firstName
}
