<div class="card">
    <div class="card-header">
      <div class="modal-heading">
        <img src="../../../../assets/images/add-icon.png" alt="">
        <span>Edit Category</span>
      </div>
      <div>
        <div class="close">
          <img (click)="close()" src="../../../../assets/images/close-modal.png" alt="">
        </div>
      </div>
    </div>
    <div class="card-body">
      <form>
        <div class="form-group">
          <label for="categoryName">Category Name</label>
          <input class="form-control" type="text" [(ngModel)]="courses.categoryName" name="categoryName" placeholder="Enter category name">
        </div>
  
        <div class="form-group" style="margin-bottom: 16px;">
          <label for="categoryImage" style="font-weight: bold; margin-bottom: 8px; display: block;">Category Image</label>
          <div class="image-drop" style="border: 2px dashed #ddd; border-radius: 8px; padding: 16px; text-align: center; position: relative; background-color: #f9f9f9;">
            <input type="file" (change)="onFileSelected($event)" accept="image/*" hidden #fileInput>
            <div *ngIf="!imageUrl" class="container-1" (dragover)="onDragOver($event)" (drop)="onDrop($event)" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
              <img class="upload-icon" src="../../../../assets/images/gallery-add.png" style="width: 40px; height: 40px;">
              <p class="upload-text" style="margin: 8px 0;">Drag or Drop image here</p>
              <p class="upload-text" style="margin: 8px 0;">or</p>
              <button class="btn btn-outline-primary" (click)="fileInput.click()" style="border: 1px solid #007bff; color: #007bff; background: transparent; padding: 8px 16px; border-radius: 4px;">Browse Files</button>
            </div>
            <img class="preview" *ngIf="courses.image" [src]="courses.image" alt="Uploaded Image" style="width: 280; height: 200px; border-radius: 8px; margin-top: 10px;">
            <div *ngIf="imageUrl" class="setting-edit" (click)="fileInput.click()">
              <img src="../../../assets/images/setting-edit.png" alt="">
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="d-flex align-items-center justify-content-between gap-1 w-100">
            <label for=" subcategories">Sub-Categories </label> <span class="add-more" *ngIf="subcategories.length < 5" (click)="addSubcategory()">+</span>
          </div>
          <div *ngFor="let subcategory of subcategories; let i = index" class="input-group mb-2">
            <div class="d-flex align-items-center justify-content-center gap-1 w-100">
              <input type="text" class="form-control" [(ngModel)]="subcategory.subCategoryName" name="subcategory-{{i}}"
                placeholder="Enter subcategory name" required>
              <button type="button" class="btn btn-danger" *ngIf="i > subCategoryCount-1" (click)="removeSubcategory(i)">Remove</button>
            </div>
          </div>
        </div>

<div class="w-100 d-flex align-items-center justify-content-end gap-1">
    <button type="button" class="btn btn-primary btn-primary-add " (click)="submit()" [disabled]="spinner">
        <span *ngIf="spinner" class="spinner-border spinner-border-sm me-2" role="status " aria-hidden="true"></span>
        {{spinner ? 'Submiting...':'submit'}}
    </button>
</div>
        
      </form>
    </div>
  </div>
  