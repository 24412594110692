export const acl: any = {
    ADMIN: ['Dashboard', 'Professions', 'Category', 'Subjects', 'Program', 'Courses', 'Articles', 'Plans', 'Page Management', 'Gallery', 'FAQs'],
    USER: [],
    TUTOR: [],
    GUEST: [],
    SITE_MANAGER: ['Dashboard', 'Articles', 'Page Management', 'Gallery'],
    ACADEMIC_MANAGER: ['Dashboard', 'Professions', 'Category', 'Subjects', 'Program', 'Courses', 'Articles', 'Plans', 'Gallery', 'FAQs'],
    SUPER_ADMIN: ['Dashboard', 'Professions', 'Category', 'Subjects', 'Program', 'Courses', 'Articles', 'Plans', 'Page Management', 'User Management', 'Gallery', 'FAQs']

}

// [
//     {
//       name: "Dashboard",
//       route: "/portal/dashboard",
//       icon: "0.png",
//       isOpen: false,
//       dropdown: []

//     },
//     {
//       name: "Metrics",
//       route: "",
//       icon: "3.png",
//       isOpen: false,
//       dropdown: [

//         {
//           name: "Pathway Metrics",
//           path: "/portal/metrics/pathway-metrics"
//         },
//       ]
//     },
//     {
//       name: "Professions",
//       route: "/portal/professions-management",
//       icon: "icons8-portrait-96.png",
//       isOpen: false,
//       dropdown: []

//     },
//     {
//       name: "Category ",
//       route: "/portal/category-management",
//       icon: "edit.png",
//       isOpen: false,
//       dropdown: []

//     },

//     {
//       name: "Subjects",
//       route: "/portal/subjects-management",
//       icon: "icons8-school-96.png",
//       isOpen: false,
//       dropdown: []

//     },

//     {
//       name: "Program",
//       route: "/portal/program-management",
//       icon: "p.png",
//       isOpen: false,
//       dropdown: []

//     },
//     {
//       name: "Courses",
//       route: "/portal/course-management",
//       icon: "1.png",
//       isOpen: false,
//       dropdown: []

//     },
//     {
//       name: "Articles",
//       route: "/portal/article-management",
//       icon: "icons8-article-30.png",
//       isOpen: false,
//       dropdown: []

//     },
//     {
//       name: "Plans ",
//       route: "/portal/plan-management",
//       icon: "6.png",
//       isOpen: false,
//       dropdown: []

//     },

//     {
//       name: "Page Management",
//       route: "",
//       icon: "3.png",
//       isOpen: false,
//       dropdown: [

//         {
//           name: "Home Management",
//           path: "/portal/page-management/Home"
//         },
//         {
//           name: "Community Management",
//           path: "/portal/page-management/Community"
//         },
//         {
//           name: "Subscription Management",
//           path: "/portal/page-management/Subscription"
//         },
//         {
//           name: "Goals Management",
//           path: "/portal/page-management/Goals"
//         },
//         {
//           name: "Pathways and Programs Management",
//           path: "/portal/page-management/Pathways-and-Programs"
//         },
//         {
//           name: "Gallery Management",
//           path: "/portal/page-management/Gallery"
//         },
//         {
//           name: "Plans Management",
//           path: "/portal/page-management/Plans"
//         },
//         {
//           name: "Events Management",
//           path: "/portal/page-management/Events"
//         },
//         {
//           name: "Contact Us Management",
//           path: "/portal/page-management/Contact-Us"
//         },
//         {
//           name: "About Us Management",
//           path: "/portal/page-management/About-Us"
//         },
//         {
//           name: "Privacy Policy Management",
//           path: "/portal/page-management/Privacy-Policy"
//         },
//         {
//           name: "Terms and Condition Management",
//           path: "/portal/page-management/Terms-and-Condition"
//         },
//         {
//           name: "Research and Development Management",
//           path: "/portal/page-management/Research-and-Development"
//         },
//         {
//           name: "Cookies Policy",
//           path: "/portal/page-management/cookies-policy"
//         },
//       ]

//     },
//     {
//       name: "User Management",
//       route: "",
//       icon: "2.png",
//       isOpen: false,
//       dropdown: [
//         {
//           name: "Admin Management",
//           path: "/portal/admin-management"
//         },
//         {
//           name: "Tutor Management",
//           path: "/portal/tutor-management"
//         },
//         {
//           name: "Learner Management",
//           path: "/portal/learner-management"
//         }
//       ],
//       restrictedToSuperAdmin: true

//     },
//     {
//       name: "Gallery",
//       route: "/portal/gallery-management",
//       icon: "icons8-gallery-48.png",
//       isOpen: false,
//       dropdown: []

//     },


//     {
//       name: "FAQs",
//       route: "/portal/faqs",
//       icon: "icons8-faq-100 (1).png",
//       isOpen: false,
//       dropdown: []

//     },