import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './basic-components/table/table.component';
import { LayoutComponentsModule } from './layout-components/layout-components.module';
import { AssignCourseComponent } from './modals/assign-course/assign-course.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssignPathwayComponent } from './modals/assign-pathway/assign-pathway.component';
import { DurationPipePipe } from './pipes/duration-pipe.pipe';
import { CourseInfoComponent } from './modals/course-info/course-info.component';
import { ProgramInfoComponent } from './modals/program-info/program-info.component';
import { EditCategoryComponent } from './modals/edit-category/edit-category.component';
import { AddPlansComponent } from './modals/add-plans/add-plans.component';
import { AddCategoryComponent } from './modals/add-category/add-category.component';
import { TutorInfoComponent } from './modals/tutor-info/tutor-info.component';
import { LearnerInfoComponent } from './modals/learner-info/learner-info.component';
import { RejectionModalComponent } from './modals/rejection-modal/rejection-modal.component';
import { ArticlePreviewComponent } from './modals/article-preview/article-preview.component';
import { GalleryPreviewComponent } from './modals/gallery-preview/gallery-preview.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CookieConsentComponent } from './modals/cookie-consent/cookie-consent.component';
import { AddUserComponent } from './modals/add-user/add-user.component';
// import { RejectionModalComponent } from './modals/rejection-modal/rejection-modal.component';
// import { AddCategoryComponent } from './modals/add-category/add-category.component';



@NgModule({
  declarations: [
    TableComponent,
    AssignCourseComponent,
    AssignPathwayComponent,
    DurationPipePipe,
    CourseInfoComponent,
    ProgramInfoComponent,
    EditCategoryComponent,
    AddPlansComponent,
    AddCategoryComponent,
    TutorInfoComponent,
    LearnerInfoComponent,
    RejectionModalComponent,
    ArticlePreviewComponent,
    CookieConsentComponent,
    GalleryPreviewComponent,
    ArticlePreviewComponent,
    AddUserComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    NgxDocViewerModule,
    LayoutComponentsModule
    // NgSelectModule
  ],
  exports: [
    TableComponent,
    LayoutComponentsModule,
    AssignCourseComponent,
    DurationPipePipe,
    CourseInfoComponent,
    CommonModule,
    CookieConsentComponent,
    ArticlePreviewComponent
  ],




})
export class SharedModule { }
