<div class="card">
    <div class="card-header">
        <div class="modal-heading">
            <span>Reject Reason</span>
        </div>
        <div>
            <div class="close">
                <img (click)="close()" src="../../../../assets/images/close-modal.png" alt="">
            </div>
        </div>
    </div>
    <div class="card-body">
        <textarea class="desc" name="" id="" type="text" [(ngModel)]="rejectionReason"
            placeholder="Insert Rejection Reason here..." minlength="200"></textarea>
        <div class="d-flex align-item-center justify-content-end w-100">
            <button class="btn btn-primary" (click)="submit()" [disabled]="rejectionReason === ''">
                Submit
            </button>
        </div>
    </div>
</div>