import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class TutorInfoService {

  constructor(private http: HttpClient) { }



  // getTutorProfile(id :any): Observable<any> {
  //   return this.http.get(environment.apiUrl + `/courses-and-programs/${id}`)
  // }
  getCourseWithLo(payload: any): Observable<any> {
    return this.http.get(environment.apiUrl + '/course/courses-with-lo-for-tutor?tutorId=' + payload)
  }
  // /metric/learning-outcome-score/{learning-outcome-id}/all-learners
  getLearningOutcomeScore(payload: any, id: any): Observable<any> {
    // return this.http.get(environment.apiUrl + `/learning-outcome-score/${payload}/all-learners?tutorId=` + id)
    return this.http.get(environment.apiUrl + `/metric/learning-outcome-score/${payload}/all-learners?tutorId=` + id)
  }

  getTutorProfile(id: any): Observable<any> {
    return this.http.get(environment.apiUrl + `/courses-and-programs/any/${id}`)
  }
  ///kpis/tutor/yearly-course-rating?courseId=
  //kpis/tutor/tutor-metric-no
  getTutorMetrics(payload: any): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `/kpis/tutor/tutor-metric-no?id=${payload} `);
  }
  ///kpis/tutor/yearly-course-rating?courseId=
  getYearlyCourseRating(payload: any): Observable<any> {
    return this.http.get(environment.apiUrl + '/kpis/tutor/yearly-course-rating?courseId=' + payload)
  }

}
