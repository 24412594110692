import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-program-info',
  templateUrl: './program-info.component.html',
  styleUrls: ['./program-info.component.scss']
})
export class ProgramInfoComponent {
  program!: any;
  constructor(private activeModal: NgbActiveModal) {

  }
  close() {
    this.activeModal.close()
  }
}
