import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LearnerInfoService } from './learner-info.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-learner-info',
  templateUrl: './learner-info.component.html',
  styleUrls: ['./learner-info.component.scss']
})
export class LearnerInfoComponent {
  learner!: any;
  coursesOngoning:any[]=[]
  coursesExpired:any[]=[]
  coursesCompleted:any[]=[]

  loader: boolean = false;

CoursesName=[
  {
    name:"Advance Data Structures and AlgorithmsData Structures and Algorithms Data Structures and AlgorithmsData Structures and Algorithms"
  },
  {
    name:"Advance Data Structures and Algorithms"
  },
  {
    name:"Advance Data Structures and Algorithms"
  },
  {
    name:"Advance Data Structures and Algorithms"
  },
  {
    name:"Advance Data Structures and Algorithms"
  },


]

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private service:LearnerInfoService
  ) { }

  ngOnInit(){
    console.log(this.learner);
    
    this.loader=true
    lastValueFrom(this.service.getLearnerCourse(this.learner.id)).then(
      (res) => {
        if (res.status == '200') {
          this.coursesOngoning = res.data.ongoing
          this.coursesExpired = res.data.expired
          this.coursesCompleted = res.data.completed
          this.loader=false
        }else {
          this.loader=false
        }
      }, (err) => {
        this.loader=false
        // this.full_Screen_Spinner=false
      })
  }

  close() {
    this.activeModal.close()
  }
}
