import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCategoryService } from '../add-category/add-category.service';

@Component({
  selector: 'app-rejection-modal',
  templateUrl: './rejection-modal.component.html',
  styleUrls: ['./rejection-modal.component.scss']
})
export class RejectionModalComponent {
  spinner: boolean = false;
  rejectionReason: string = '';
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  close(): void {
    this.activeModal.close();
  }
  submit() {
    this.activeModal.dismiss(this.rejectionReason);
  }
}
