import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter, lastValueFrom, Subject, Subscription } from 'rxjs';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {



  topButtonThresholdPercentage: number = 0.5; // 50% of viewport height
  bottomButtonThresholdPercentage: number = 0.5; // 50% of viewport height
  searchSpinner: boolean = false;
  showTopButton: boolean = false;
  showBottomButton: boolean = false;

  private topButtonThreshold: number = 0;
  private bottomButtonThreshold: number = 0;
  searchSubject: Subject<string> = new Subject<string>();

  type: any = 'COURSE';
  search: any = '';
  show = false;
  role: any

  picture = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).pictureLink : null
  header = '#EDD64E'
  user = localStorage.getItem('token')
  // showTopButton: boolean | undefined;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private service: HeaderService,
  ) {
    this.searchSubject.pipe(debounceTime(500))  // Wait for 300ms of silence before triggering search
      .subscribe(searchText => {
        this.searchValue();
      });
  }
  private routeSubscription!: Subscription;
  currentRoute!: string;
  hideDiv = false;
  ngOnInit(): void {


    this.calculateThresholds();
    window.addEventListener('resize', this.calculateThresholds.bind(this)); // Recalculate on resize

    this.role = localStorage.getItem("role") ? JSON.parse(localStorage.getItem("role")!) : null;
    console.log(this.role);

    let currentUrl = this.router.url.split('?')[0];

    this.router.events.subscribe((val) => {
      console.log(this.router.url)

      this.hideDiv = this.router.url === '/main' ? true : false;
      if (this.router.url === '/main/goals') {
        this.header = '#EDD64E'
      } else if (this.router.url === '/main/tutor/dashboard'
        || this.router.url === '/main/create-course-outline'
        || this.router.url === '/main/create-course-outline'
        || this.router.url === '/main/preview-course-outline'
        || this.router.url === '/main/notifications'
        || this.router.url === '/main/notifications-all-tutor'
        || this.router.url === '/main/idea-track-dashboard'
        || this.router.url === '/main/learner-dashboard'
        || this.router.url === '/main/learner/dashboard'
        || this.router.url === '/main/course-details'
        || this.router.url === '/main/create-session'
        || this.router.url === '/main/metrics-tutor'
        || this.router.url === '/main/tutor/course-create'
        || this.router.url === '/main/tutor/preview-course'
        || this.router.url.includes('/main/learner')
        || this.router.url.includes('/main/tutor')
        && !this.router.url.includes('/main/tutor-profile')


      ) {
        this.header = '#dceaf0'
      } else if (this.router.url === '/main/purchase-history' ||
        currentUrl === '/main/lectures'
      ) {
        this.header = '#ffffff'
      }
      // else if (this.router.url === '/main/terms-condition') {
      //   this.header = '#ffffff'
      // }
      // else if (this.router.url === '/main/privacy-policy') {
      //   this.header = '#ffffff'
      // }

      else {
        this.header = '#B9D8E7'
      }
    });
  }

  login() {
    localStorage.clear()
    this.router.navigate(['/auth'])
  }


  isDropdownOpen = false;

  toggleDropdown() {
    console.log("click");

    this.isDropdownOpen = !this.isDropdownOpen;
  }

  navigateTo(route: string) {
    switch (route) {
      case 'home':
        this.router.navigate(['/main']);
        break;
      case 'Plans':
        this.router.navigate(['/main/plans']);
        break;
      case 'ContentFilter':
        this.router.navigate(['/main/content-filter']);
        break;
      case 'learnerDashboard':
        this.router.navigate(['/main/learner/dashboard']);
        break;
      case 'tutorDashboard':
        this.router.navigate(['/main/tutor/dashboard']);
        break;
      default:
        break;
    }
    this.isDropdownOpen = false; // Close the dropdown after selection
  }

  onSearchInput(searchText: string) {
    this.searchSubject.next(searchText);  // Push the new search term into the subject
  }


  searchList: any[] = []
  searchValue() {
    if (this.search === '') {
      this.searchList = []
      return
    }
    this.searchSpinner = true;
    lastValueFrom(this.service.search({ search: this.search, type: this.type })).then((res) => {

      this.searchList = res.data.content
      this.searchSpinner = false;
    })
  }
  redirectSearch(item: any) {
    if (item.courseId) {
      this.router.navigate(['/main/course-intro'], { queryParams: { id: item.courseId } });
    }
    if (item.programId) {
      this.router.navigate(['/main/program'], { queryParams: { id: item.programId } });
    }
  }

  navigate() {
    this.router.navigate(['/main']);
  }

  private calculateThresholds() {
    const viewportHeight = window.innerHeight;
    this.topButtonThreshold = viewportHeight * this.topButtonThresholdPercentage;
    this.bottomButtonThreshold = viewportHeight * this.bottomButtonThresholdPercentage;
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    const scrollY = window.scrollY;
    const documentHeight = window.document.body.scrollHeight;
    const viewportHeight = window.innerHeight;

    // Check if the user has scrolled down more than the dynamic threshold for the top button
    this.showTopButton = scrollY > this.topButtonThreshold;

    // Adjust visibility based on the distance to the bottom of the page
    this.showBottomButton = ((documentHeight - viewportHeight) - scrollY) > this.bottomButtonThreshold && this.showTopButton;
  }

  // checkScroll() {
  //   if (this.router.url !== '/main') {
  //     this.router.navigate(['/main']);
  //   }
  //   else {
  //     this.scrollToMiddle();
  //   }
  // }

  checkScroll() {
    this.router.navigate(['/main/content-filter'], {
      queryParams: {
        hours: '1'
      }
    });
  }

  scrollToMiddle() {
    const remValue = parseFloat(getComputedStyle(document.documentElement).fontSize);
    // const distance = this.findDistanceFromTop('Pathways');
    // console.log(distance);
    window.scrollTo({
      top: 772 - (5 * remValue) - (5 * remValue) + 609.6 + (2.5 * remValue) + 457.2 + 656.6 + 679.8 + 900, // Scroll to half of the document's height
      // top: document.body.scrollHeight / 1.6, // Scroll to half of the document's height
      behavior: 'smooth' // Smooth scrolling effect
    });
  }
}



