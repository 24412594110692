import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { LecturesService } from 'src/app/main/lectures/lectures.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-course-info',
  templateUrl: './course-info.component.html',
  styleUrls: ['./course-info.component.scss']
})
export class CourseInfoComponent {
  course!: any;

  selectedData: any
  selectedItem: any;
  showContent: boolean = false;
  loader: boolean = true;
  courseDetails: any[] = []
  constructor(
    private activeModal: NgbActiveModal,
    private Service: LecturesService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private ngxspinner: NgxSpinnerService
  ) {

  }

  ngOnInit() {
    console.log(this.course, "course");

    this.course.level = this.course.level.replace(/_/g, ' ');

    this.activeRouter.queryParams.subscribe((params: any) => {
      if (this.course.id) {
        this.loader = true
        this.Service.getdocuments(this.course.id).subscribe(
          (res) => {
            if (res) {
              this.ngxspinner.hide()
              this.courseDetails = res.data
              // 

              this.courseDetails.forEach((item: any) => {
                item.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + item.documentId + '?controls=1&autoplay=0&loop=0&dnt=1&title=0&byline=0&portrait=0&badge=0&share=0&watchlater=0');
              });
              this.showContent = true;
              this.loader = false
              console.log(this.courseDetails);
              // Select the first item by default
              if (this.courseDetails.length > 0) {
                this.selectedItemToShow = this.courseDetails[0];
              }
              else {
                this.showContent = false;
                this.loader = false

                // Swal.fire('No data found', 'Please wait for data to be added', 'info')
                Swal.fire({
                  title: 'No data found',
                  text: 'Please wait for data to be added',
                  icon: 'info',
                  confirmButtonColor: '#0075AB',
                }).then((res) => {

                  this.close()
                })
                // this.router.navigate(['/portal/course-management'])
              }
            } else {
              this.showContent = false;
              this.loader = false

              Swal.fire('No data found', 'Please wait for data to be added', 'info').then((res) => {

                this.close()
              })

              // this.router.navigate(['/portal/course-management'])

              this.ngxspinner.hide()
            }
          },
          (error) => {
            this.showContent = false;
            this.loader = false

            this.ngxspinner.hide()
            Swal.fire('No data found', 'Please wait for data to be added', 'info').then((res) => {

              this.close()
            })
            // this.router.navigate(['/main'])

          }
        );
      }
    });
  }
  close() {
    this.activeModal.close()
  }

  selectedItemToShow: any = {};
  // getSelectedItem(){
  //   return this.courseDetails.filter(x=> x.selected)[0]
  // }

  selectItem(selectedItem: any) {

    // console.log(selectedItem);

    // // Deselect all items
    // this.courseDetails.forEach(item => item.selected = false);

    // // Select the clicked item
    // selectedItem.selected = true;
    this.selectedItemToShow = selectedItem
  }



  next() {
    if (this.courseDetails.length - 1 === this.courseDetails.indexOf(this.selectedItemToShow)) {
      return
    }
    let index = this.courseDetails.indexOf(this.selectedItemToShow)
    this.selectedItemToShow = this.courseDetails[index + 1]
  }



  previous() {
    if (this.courseDetails.indexOf(this.selectedItemToShow) === 0) {
      return
    }
    let index = this.courseDetails.indexOf(this.selectedItemToShow)
    this.selectedItemToShow = this.courseDetails[index - 1]

  }
  isNextDisabled(): boolean {
    const currentIndex = this.courseDetails.indexOf(this.selectedItemToShow);
    return currentIndex === this.courseDetails.length - 1;
  }

  isPreviousDisabled(): boolean {
    return this.courseDetails.indexOf(this.selectedItemToShow) === 0;
  }
}

