import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditCategoryService } from './edit-category.service';
import { lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent implements OnInit {
  @Input() courses: any;

  spinner: boolean = false
  categoryName: string = '';
  categoryImage: File | null = null;
  isActive: boolean = false;
  id: any
  subcategories: any[] = [];
  imageUrl: string | ArrayBuffer | null = null;

  constructor(public activeModal: NgbActiveModal, private service: EditCategoryService) { }

  ngOnInit(): void {
    this.mappingCategoryData()
  }

  subCategoryCount: number = 0
  mappingCategoryData() {
    if (this.courses) {
      this.categoryName = this.courses.categoryName || '';
      this.isActive = this.courses.isActive || false;
      this.imageUrl = this.courses.image || null;
      this.id = this.courses.id

      this.subCategoryCount = this.courses.subCategories.length
      this.courses.subCategories.map((x: { subCategoryName: string[] }) => this.subcategories.push(x))

    }
  }
  close() {
    this.activeModal.dismiss();
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.categoryImage = file;
      const reader = new FileReader();
      reader.onload = (e: any) => this.imageUrl = e.target.result;
      reader.readAsDataURL(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    if (file) {
      this.categoryImage = file;
      const reader = new FileReader();
      reader.onload = (e: any) => this.imageUrl = e.target.result;
      reader.readAsDataURL(file);
    }
  }

  // Method to add a new subcategory input
  addSubcategory() {
    console.log(this.subcategories.length)
    this.subcategories.push({
      "subCategoryId": "",
      "subCategoryName": ""
    });
  }

  // Method to remove a subcategory input
  removeSubcategory(index: number) {
    if (this.subcategories.length > 1) {
      this.subcategories.splice(index, 1);
    }
  }

  async submit() {
    this.spinner = true

    let payload = {
      categoryName: this.courses.categoryName,
      categoryId: this.courses.id,
      image: this.courses.image,
      subCategories: this.courses.subCategories
      // formData.append('isActive', this.isActive.toString());
    }

    let fd = new FormData()
    fd.append('categoryId', this.courses.id)
    fd.append('categoryName', this.courses.categoryName)
    if (this.categoryImage) {
      fd.append('thumbnail', this.categoryImage);
    }

    this.subcategories.forEach((element: any, index: number) => {
      if (element.subCategoryName) {
        fd.append('subCategories[' + index + '].subCategoryId', element.id ? element.id : '')
        fd.append('subCategories[' + index + '].subCategoryName', element.subCategoryName)
      }
    });


    try {
      const res = await lastValueFrom(this.service.editCategory(fd));
      if (res.status === 201) {
        this.spinner = false
        // Handle success
        console.log('Category updated successfully');
        Swal.fire({
          title: 'Success',
          text: 'Category Updated successfully',
          icon: 'success',
          confirmButtonColor: '#0075AB',
        })

        this.close(); // Optionally close the modal on success
        //window.location.reload();



      }
      else {
        this.spinner = false
        Swal.fire({
          title: 'Error',
          text: 'Something went wrong',
          icon: 'error',
          confirmButtonColor: '#0075AB',
        })

        // this.close(); // Optionally close the modal on success
        // window.location.reload();
      }
    } catch (error) {
      // Handle error
      this.spinner = false
      console.log('Error updating category:', error);
    }
  }


}
