import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class PageManagementTemplateService {
  constructor(private http: HttpClient) { }

  getPageByName(payload: any): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `/page/all?name=${payload.pageName}`)
  }

  addContent(payload: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + `/block`, payload)
  }

  sectionStatus(payload: any): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + `/block/view-status`, payload)
  }
  getBlocks(payload: any): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `block/${payload}/blocks`)
  }
}