<div class="card">
    <div class="card-header">
        <div class="modal-heading">
            <img src="../../../../assets/images/add-icon.png" alt="">
            <span>Add Program</span>
        </div>
        <div>
            <div class="close">
                <img (click)="close()" src="../../../../assets/images/close-modal.png" alt="">
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class=" search-body">
            <div class="search-container">
                <input class="searchbar" type="search" placeholder="Search...">
                <span class="search-icon">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.6725 14.6412L19 19M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
                            stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </span>
            </div>
        </div>
        <div class="course-checkbox">
            <ng-container *ngFor="let item of courses; let i = index;">
                <div class="courses">
                    <input type="checkbox" [id]="'check'+i" [(ngModel)]="item.active"     class="custom-checkbox">
                    <label [for]="'check'+i">{{item.programName}}</label>
                </div>
            </ng-container>
            <div class="w-100 d-flex align-items-center justify-content-end gap-1">
                <button class="btn btn-outline-primary" (click)="clearAll()">Clear all</button>
                <button class="btn btn-primary" (click)="save()">Save</button>
            </div>
        </div>
    </div>
</div>