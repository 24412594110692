import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { ArticleService } from 'src/app/main/article/article.service';

@Component({
  selector: 'app-article-preview-layout',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./article-preview.component.scss']
})
export class ArticlePreviewComponent {
  article!: any;
  articleId: string = '';
  constructor(
    private service: ArticleService,
    private activeRoute: ActivatedRoute,
    private ngxSpinner: NgxSpinnerService

  ) {

  }
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      if (params['id']) {
        this.articleId = params['id']
        this.fetchArticle()
      }
      this.fetchArticle()
    })
  }

  fetchArticle() {
    this.ngxSpinner.show()
    lastValueFrom(this.service.getArticleById(this.articleId)).then((res) => {
      this.article = res.data
      this.ngxSpinner.hide()
    })
  }
}
