<div class="space-sidebar">
    <div class="admin-header">ADMIN TOOLS</div>
    <div class="nav-align">
        <div>
            <ng-container *ngFor="let item of navlist">
                <div *ngIf="item.dropdown.length === 0" class="nav-items" [routerLink]="item.route"
                    routerLinkActive="active active-pink" [routerLinkActiveOptions]="{exact: true}"
                    [class.disabled]="item.isOpen ? false : getNavTrue()">

                    <div class="nav-item">
                        <div class="item">
                            <img class="icon" [src]="'../../../../assets/images/'+item.icon" alt="">
                            <div class="course">{{item.name}}</div>
                        </div>
                        <img class="down-arrow"
                            [src]="item.isOpen ? '../../../../assets/images/up-arrow.png' : '../../../../assets/images/down-arrow.png'"
                            alt="" *ngIf="item.dropdown.length !== 0">
                    </div>
                    <ng-container *ngIf="item.dropdown.length !== 0">
                        <ng-container *ngIf="item.isOpen">
                            <div class="option" *ngFor="let dropdownItem of item.dropdown">
                                <div class="option-item" [routerLink]="dropdownItem.route" routerLinkActive="active"
                                
                                    [routerLinkActiveOptions]="{exact: true}">
                                    <img class="line-arrow" src="../../../../assets/images/line arrow.png" alt="">
                                    <label>{{dropdownItem.label}}</label>
                                </div>
                                <img class="right" src="../../../../assets/images/right.png" alt="">
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <div *ngIf="item.dropdown.length !== 0" class="nav-items" [class.active]="item.isOpen"
                routerLinkActive="active active-pink"
                [routerLinkActiveOptions]="{exact: true}"
                    [class.active-pink]="item.isOpen && item.dropdown.length === 0"
                    
                    [class.disabled]="item.isOpen ? false : getNavTrue()">
                    <div class="nav-item" (click)="item.isOpen =!item.isOpen">
                        <div class="item">
                            <img class="icon" [class.filter]="item.isOpen"
                                [src]="'../../../../assets/images/'+item.icon" alt="">
                            <div class="course">{{item.name}}</div>
                        </div>
                        <img class="down-arrow"
                        
                            [src]="item.isOpen ? '../../../../assets/images/up-arrow.png' : '../../../../assets/images/down-arrow.png'"
                            alt="" *ngIf="item.dropdown.length !== 0">
                    </div>
                    <ng-container *ngIf="item.dropdown.length !== 0">
                        <!-- <ng-container *ngIf="item.isOpen">
                            <div class="option" *ngFor="let dropdownItem of item.dropdown">
                                <div class="option-item" [routerLink]="dropdownItem.route" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    <img class="line-arrow" src="../../../../assets/images/line arrow.png" alt="">
                                    <label>{{dropdownItem.label}}</label>
                                </div>
                                <img class="right" src="../../../../assets/images/right.png" alt="">
                            </div>
                        </ng-container> -->

                        <ng-container *ngIf="item.isOpen">
                            <ng-container *ngFor="let item of item.dropdown">
                                <div class="option" [routerLink]="item.path">
                                    <div class="option-item">
                                        <img class="line-arrow" src="../../../../assets/images/line arrow.png" alt="">
                                        <label>{{item.name}}</label>
                                    </div>
                                    <img class="right" src="../../../../assets/images/right.png" alt="">
                                </div>

                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <button class="btn" (click)="logout()">
            <img src="../../../../assets/images/Frame.png" alt="">
            <div class="logout">Logout</div>
        </button>
    </div>
</div>