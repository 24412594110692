import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery-preview',
  templateUrl: './gallery-preview.component.html',
  styleUrls: ['./gallery-preview.component.scss']
})
export class GalleryPreviewComponent {
  tags: any[] = [];
  gallery!: any;
  selectedImage: any;
  urlOne: any;
  urlTwo: any;
  urlThree: any;
  urlFour: any;
  constructor(
    private ngbActiveModal: NgbActiveModal,
  ) { }

  close() {
    this.ngbActiveModal.close();
  }


}
