<div class="course-info">
    <div class="course-modal-header">
        <!-- <img [src]="tutor.pictureLink" alt=""> -->
        <div class="close-btn" (click)="close()">
            <img src="../../../../assets/images/close-modal.png" alt="close-btn">
        </div>
    </div>
    <div class="course-modal-body">
        <div class="modal-section">
            <div class="course-introduction">
                <div class="course-info-title">
                    <h2>{{ "Learner Profile" | titlecase }}</h2>
                    <!-- <p [innerHTML]="tutor.courseDescription"></p> -->
                </div>
                <div class="course-tutor">
                    <img [src]="learner.pictureLink" (error)="learner.pictureLink = '../../../../assets/images/3.png'"
                        alt="">

                        <div class="d-flex flex-column">
                            <div class="tutor-name">
                                {{ (learner?.firstName | titlecase)|| "-"  + " " + (learner?.lastName | titlecase) || "-" }}
                              </div>
                              
                            <h6>{{learner.username}}</h6>
                        </div>
                  
                    
                </div>
            </div>
            <div class="social-icons">

                <!-- <div class="social-containers">
                    <img src="../../../../assets/images/contacts.png" alt="">
                    <div class="labels">
                        <label class="level" for="text">Enrolled Students</label>
                        <label for="number">{{course.enrollment || 0}}</label>
                    </div>
                </div> -->
                <div class="social-containers">
                    <div style="  width: 50px; height: 50px;background-color: #0075ab;border-radius: 12px;padding: 6px">
                        <img style=" width: 40px; height: 40px; "
                            src="../../../../assets/images/icons8-email-48 (1).png" alt="">
                    </div>
                    <div class="labels">
                        <label class="level" for="text">Email</label>
                        <label for="number">{{learner.email || "-"}}</label>
                    </div>
                </div>
                <div class="social-containers">
                    <div style="  width: 50px; height: 50px;background-color: #0075ab;border-radius: 12px;padding: 6px">
                        <img style=" width: 40px; height: 40px;"
                            src="../../../../assets/images/icons8-phone-number-50 (2).png" alt="">
                    </div>

                    <div class="labels">
                        <label class="level" for="text">Contact NO</label>
                        <label for="number">{{learner.phoneNumber ||"-"}}</label>
                    </div>
                </div>
            </div>
        </div>


        <div class="modals-section" *ngIf="!loader">
            <div class="course-introduction">
                <div class="course-info-title">
                    <h2>{{ "Learner Courses" | titlecase }}</h2>
                    <!-- <p [innerHTML]="tutor.courseDescription"></p> -->
                </div>
        
                <div *ngIf="coursesOngoning.length > 0 || coursesCompleted.length > 0 || coursesExpired.length > 0">
                    <div class="course-tutor" *ngFor="let item of coursesOngoning">
                        <img [src]="item.image" (error)="item.image = '../../../../assets/images/3.png'" alt="">
                        <div  class="d-flex justify-content-around flex-column">
                            <div class="tutor-name">{{ item.courseName | titlecase }}</div>
                            <div class="">Ongoing</div>
                        </div>
                        
                    </div>
        
                    <div class="course-tutor" *ngFor="let item of coursesCompleted">
                        <img [src]="item.image" (error)="item.image = '../../../../assets/images/3.png'" alt="">
                        <div class="d-flex justify-content-around flex-column">
                        <div class="tutor-name">{{ item.courseName | titlecase }}</div>
                        <div class="">Completed</div>
                        </div>
                    </div>
        
                    <div class="course-tutor" *ngFor="let item of coursesExpired">
                        <img [src]="item.image" (error)="item.image = '../../../../assets/images/3.png'" alt="">
                        <div  class="d-flex justify-content-around flex-column">
                            <div class="tutor-name">{{ item.courseName | titlecase }}</div>
                            <div class="">Expired</div>
                        </div>
                        
                    </div>
                </div>
        
                <div *ngIf="coursesOngoning.length <= 0 && coursesCompleted.length <= 0 && coursesExpired.length <= 0"
                    class="name d-flex justify-content-center align-items-center">
                    Course not available
                </div>
            </div>
        </div>
        
        <div *ngIf="loader" class="name d-flex justify-content-center align-items-center mt-4">
            <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading...
        </div>
        

    </div>
</div>