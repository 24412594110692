<div class="gallery-preview">
    <div class="d-flex align-item-center justify-content-end close_preview">
        <div class="btn-close" (click)="close()">
        </div>
    </div>
    <div class="preview">
        <div class="row">
            <div class="col-md-4" *ngIf="selectedImage">
                <div class="main_image">
                    <img [src]="selectedImage" alt="">
                </div>
                <div class="sub_images">
                    <img [src]="urlOne" [class.selected]="urlOne === selectedImage" (click)="selectedImage = urlOne"
                        *ngIf="urlOne" alt="">
                    <img [src]="urlTwo" [class.selected]="urlTwo === selectedImage" (click)="selectedImage = urlTwo"
                        *ngIf="urlTwo" alt="">
                    <img [src]="urlThree" [class.selected]="urlThree === selectedImage"
                        (click)="selectedImage = urlThree" *ngIf="urlThree" alt="">
                    <img [src]="urlFour" [class.selected]="urlFour === selectedImage" (click)="selectedImage = urlFour"
                        *ngIf="urlFour" alt="">
                </div>
            </div>
            <div [class.col-md-8]="selectedImage" [class.col-md-12]="!selectedImage">
                <div class="article_body">
                    <div class="article_heading">

                    </div>
                    <div class="article_content w-100">
                        <h3>{{gallery.galleryName}}</h3>
                        <div class="gallery-body" [innerHTML]="gallery.description">
                        </div>
                        <label for="date" *ngIf="gallery.galleryDate">Date: <span>{{gallery.galleryDate}}</span></label>
                        <label for="status" *ngIf="gallery.adminStatus">Status:
                            <span>{{gallery.adminStatus}}</span></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>