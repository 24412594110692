import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AddUserService } from './add-user.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  userForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]),
    contactNumber: new FormControl('', [Validators.required]),
    department: new FormControl('', [Validators.pattern('^[a-zA-Z0-9 ]+$')]),
    designation: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')]),
    isInternal: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    roleId: new FormControl('', [Validators.required]),
    profilePicture: new FormControl('')
  });

  updateId: string = '';
  imageUrl!: string | ArrayBuffer | null;
  profileUrl!: string | ArrayBuffer | null;

  spinner: boolean = false;

  constructor(
    private modal: NgbModal,
    private router: Router,
    private activeModal: NgbActiveModal,
    private service: AddUserService
  ) {
  }

  internalList = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ]

  @ViewChild('tel', { static: true }) tel!: any;
  ngOnInit(): void {
    if (this.updateId) {
      this.tel.country = this.userForm.controls['contactNumber'].value?.includes('-') ? this.userForm.controls['contactNumber'].value?.split('-')[0] : '+92'
      this.tel.phoneNumber = this.userForm.controls['contactNumber'].value?.includes('-') ? this.userForm.controls['contactNumber'].value?.split('-')[1] : this.userForm.controls['contactNumber'].value
      this.userForm.controls['contactNumber'].setValue(this.tel.phoneNumber)
    }
    if (this.Roles.length == 0) {
      this.router.navigate(['/main/roles'])
      Swal.fire({
        title: 'Please Add Roles',
        text: 'No roles Exist in your organization. Please add roles first.',
        icon: 'info',
        confirmButtonColor: '#198FD9'
      })
      this.close()
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    this.handleFile(file, 'logo');
  }

  onFileSelected(event: Event, type: string): void {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const fileType = file?.type;

    if (allowedTypes.includes(fileType!)) {
      this.handleFile(file, type);

    }
    else {
      Swal.fire({
        title: 'Invalid File',
        text: 'Please upload either png, jpeg or jpg',
        icon: 'info',
        confirmButtonColor: '#198FD9'
      })
    }
  }


  logo: any;
  profile: any;
  private handleFile(file: File | undefined, type: string): void {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {

        if (type === 'logo') {
          this.imageUrl = reader.result;
          this.logo = file
        }
        else {
          this.profileUrl = reader.result;
          this.profile = file
        }
      };
      reader.readAsDataURL(file);
    }
  }

  close() {
    this.activeModal.dismiss(false)
  }

  submit() {
    this.spinner = true
    let payload: any = this.userForm.value
    // payload.organizationPicture = this.logo || ''
    // payload.contactPersonPicture = this.profile || ''
    payload.profilePicture = this.logo || ''
    payload.contactNumber = this.code + payload.contactNumber
    let fd = new FormData();
    Object.keys(payload).forEach(x => {
      if (payload[x].toString())
        fd.append(x, payload[x])
    })
    // // // console.log(payload)
    if (this.updateId) {
      lastValueFrom(this.service.updateUser(fd, this.updateId)).then(x => {
        this.spinner = false
        this.activeModal.dismiss(true)
      }, err => {
        this.spinner = false
      })
    } else {
      lastValueFrom(this.service.addUser(fd)).then(x => {
        this.spinner = false
        this.activeModal.dismiss(true)
      }, err => {
        this.spinner = false
      })
    }
  }
  clearAll() {
    this.userForm.reset()
    this.imageUrl = '';
    this.logo = '';
    this.tel.phoneNumber = ''
  }

  Roles: any[] = []
  getRole() {
    lastValueFrom(this.service.getRoles()).then(x => {

      this.Roles = x.data
    }, err => {

    })
  }

  code: any;
  updateForm(payload: any) {
    // this.userForm.controls['contactNumber'].setValidators([Validators.pattern('^[0-9]{' + payload.min + ',' + payload.max + '}$')]);
    if (payload.value) {
      this.userForm.controls['contactNumber'].setValidators([Validators.pattern('^[0-9]{' + payload.min + ',' + payload.max + '}$')]);
    }
    else {
      this.userForm.controls['contactNumber'].setValidators([Validators.pattern('^[0-9]{' + payload.min + ',' + payload.max + '}$'), Validators.required]);
    }
    this.userForm.controls['contactNumber'].setValue(payload.value);
    this.code = payload.code
  }
  touched() {
    this.userForm.controls['contactNumber'].markAsTouched()
  }
}

