<div class="lecture_preview">
    <div class="sections_list">
        <ng-container *ngFor="let item of courseDetails">
            <div class="section">

                <div class="d-flex align-items-center justify-content-start" style="gap:5px">

                    <input type="checkbox" class="custom-checkbox" disabled [checked]="item.completed" />
                    <label class="checkbox-container">
                        {{item.sectionName}}
                    </label>
                </div>
                <ul>
                    <ng-container *ngFor="let document of item.documents; let i = index">
                        <li (click)="selectSession(document)">
                            {{document.documentName}}</li>
                    </ng-container>
                </ul>
            </div>
        </ng-container>
    </div>
    <div class="lecture_content">


        <div class="flex-item video-flex">
            <div class="flex-video-container">
                <div class="video-main">
                    <div class="video-container">
                        <ng-container *ngIf="!showSurvey">
                            <div class="video-heading">
                                <h1>{{selectedItemToShow?.documentName |titlecase}}</h1>
                            </div>


                            <!-- <video width="100%" controls class="lms-video">
                            <source src="assets/videos/sample-video.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                        
                        -->

                            <!-- <iframe [src]="selectedItemToShow.url" class="lms-video" frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                            style="width: 100%; height: auto; aspect-ratio: 16/9;">
                        </iframe> -->

                            <!-- <iframe *ngIf="selectedItemToShow.documentType==='VIDEO'"
                            [src]="sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + selectedItemToShow.videoId + '?controls=1&autoplay=0&loop=0')"
                            class="lms-video" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen style="width: 100%; height: auto; aspect-ratio: 16/9;">
                        </iframe> -->
                            <iframe *ngIf="selectedItemToShow.documentType==='VIDEO'" [src]="selectedItemToShow.url"
                                class="lms-video" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                                allowfullscreen style="width: 100%; height: 550px; aspect-ratio: 16/9;">
                            </iframe>

                            <!-- <iframe 
                      *ngIf="selectedItemToShow.documentType === 'PDF'"
                      [src]="sanitizer.bypassSecurityTrustResourceUrl(selectedItemToShow.documentLink)"
                      class="pdf-viewer" 
                      frameborder="0" 
                      style="width: 100%; height: auto; aspect-ratio: 16/9;">
                  </iframe>
                   -->

                            <img [src]="selectedItemToShow.documentLink" alt=""
                                *ngIf="selectedItemToShow.documentType === 'IMAGE'"
                                style="width: 100%; height: 550px; aspect-ratio: 16/9;">


                            <div *ngIf="selectedItemToShow.documentType === 'PDF'">
                                <!-- <div class="image-preview-file"> -->
                                <!-- <img src="../../../assets/images/icons8-file-120.png" alt=""> -->
                                <!-- <span>{{selectedItemToShow.documentLink}}</span> -->
                                <!-- <span class="download-text">Download Material</span> -->
                                <!-- </div> -->
                                <div *ngIf="selectedItemToShow.documentLink.includes('pdf')">
                                    <ngx-doc-viewer disableContent="popout-hide"
                                        [url]="selectedItemToShow.documentLink " viewer="google"
                                        style="width:100%;height:50vh;"></ngx-doc-viewer>
                                </div>

                                <div *ngIf="selectedItemToShow.documentLink.includes('ppt')">
                                    <ngx-doc-viewer [url]="selectedItemToShow.documentLink " viewer="office"
                                        style="width:100%;height:50vh;"></ngx-doc-viewer>
                                </div>
                            </div>

                            <div *ngIf="hasquizQuestions() && quizQuestions.isComplete!==true" class="quiz-container">
                                <form [formGroup]="quizForm">

                                    <h1>{{ quizQuestions.title }}</h1>
                                    <p [innerHTML]="quizQuestions.description"></p>

                                    <!-- Loop through each question -->
                                    <div *ngFor="let question of quizQuestions.questions">
                                        <h3>{{ question.questionText }}</h3>

                                        <div *ngIf="!quizQuestions.isComplete">
                                            <div *ngIf="question.questionType === 'SINGLE'">
                                                <div *ngFor="let option of question.options">
                                                    <input type="radio" [name]="question.id" [value]="option.id"
                                                        (change)="selectOption(question.id, option.id)" />
                                                    <label>{{ option.optionText }}</label>
                                                </div>
                                            </div>

                                            <div *ngIf="question.questionType === 'MULTIPLE'">
                                                <div *ngFor="let option of question.options">
                                                    <input type="checkbox" [name]="question.id" [value]="option.id"
                                                        (change)="toggleOption(question.id, option.id)" />
                                                    <label>{{ option.optionText }}</label>
                                                </div>
                                            </div>
                                            <div *ngIf="question.questionType === 'DRAG_AND_DROP_SEQUENCE'">

                                                <div class="example-container">
                                                    <h2>Sequence</h2>
                                                    <div cdkDropList cdkDropListOrientation="horizontal"
                                                        #doneList="cdkDropList"
                                                        [cdkDropListData]=" question.targetOptions"
                                                        [cdkDropListConnectedTo]="[todoList]" class="example-list"
                                                        (cdkDropListDropped)="dropItem($event)">
                                                        <ng-container *ngFor="let item of question.targetOptions">
                                                            <div *ngIf="item.optionText" class="example-box"
                                                                class="p-4 border" cdkDrag>
                                                                {{item.optionText}}</div>
                                                            <img *ngIf="!item.optionText" cdkDrag class="example-box"
                                                                class="seq-img" [src]="item.imageUrl" alt="">
                                                        </ng-container>
                                                    </div>
                                                </div>

                                                <div class="example-container">
                                                    <h2>Options</h2>
                                                    <div cdkDropList cdkDropListOrientation="horizontal"
                                                        #todoList="cdkDropList" [cdkDropListData]="question.options"
                                                        [cdkDropListConnectedTo]="[doneList]" class="example-list"
                                                        (cdkDropListDropped)="dropItem($event)">
                                                        <ng-container *ngFor="let item of question.options">
                                                            <div *ngIf="item.optionText" class="example-box"
                                                                class="p-4 border" cdkDrag>
                                                                {{item.optionText}}</div>
                                                            <img *ngIf="!item.optionText" cdkDrag class="example-box"
                                                                class="seq-img" [src]="item.imageUrl" alt="">
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="question.questionType === 'DRAG_AND_DROP_MATRIX'">

                                                <label for="">Options</label>
                                                <div class="options-container">
                                                    <div *ngFor="let option of question.options" class="option-item"
                                                        [draggable]="true" (dragstart)="onDragStart($event, option)">
                                                        {{ option.optionText }}
                                                    </div>
                                                </div>

                                                <div class="d-flex">
                                                    <div class="grid-row">
                                                        <div class="grid-cell"></div>
                                                    </div>
                                                    <ng-container
                                                        *ngFor="let item of question.matrixConfiguration?.columnLabels">
                                                        <div class="grid-row">
                                                            <div class="grid-cell">{{ item }}</div>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="grid-container">
                                                    <div *ngFor="let row of question.cellData; let rowIndex = index"
                                                        class="grid-row">
                                                        <div class="grid-cell">
                                                            {{ question.matrixConfiguration?.rowLabels[rowIndex] }}
                                                        </div>
                                                        <div *ngFor="let cell of row; let colIndex = index"
                                                            class="grid-cell" (dragover)="onDragOver($event)"
                                                            (drop)="onDrop($event, rowIndex, colIndex, question)">
                                                            <ng-container *ngIf="cell">
                                                                <div *ngIf="cell.optionText" class="cell-item"
                                                                    (dblclick)="moveToOptions(cell, rowIndex, colIndex, question)">
                                                                    {{ cell.optionText }}
                                                                </div>
                                                                <img *ngIf="!cell.optionText && cell.imageUrl"
                                                                    class="example-box seq-img" [src]="cell.imageUrl"
                                                                    alt="">
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>




                                            </div>
                                            <div *ngIf="question.questionType === 'DRAG_AND_DROP_EQUATION'">
                                                <div class="example-container">
                                                    <h2>Equation</h2>
                                                    <div class="d-flex align-items-center gap-1">
                                                        <ng-container
                                                            *ngFor="let eq of question.equationElements; let eqIndex = index">
                                                            <div [draggable]="true"
                                                                (dragstart)="onEquationElementDragStart($event, eqIndex,question)"
                                                                (dragover)="onEquationElementDragOver($event)"
                                                                (drop)="onEquationElementDrop($event, eqIndex, question)">
                                                                <div *ngIf="!eq.isPlaceholder">
                                                                    <div *ngIf="eq.text" class="cell-item">{{ eq.text }}
                                                                    </div>
                                                                    <img *ngIf="!eq.text && eq.imageUrl"
                                                                        class="example-box seq-img" [src]="eq.imageUrl"
                                                                        alt="">
                                                                </div>
                                                                <!-- Dragging functionality for placeholders -->
                                                                <div *ngIf="eq.isPlaceholder" class="eq-blank">
                                                                    <!-- Display placeholder visual -->
                                                                    <div *ngIf="eq.optionText" class="cell-item">{{
                                                                        eq.optionText }}
                                                                    </div>
                                                                    <img *ngIf="!eq.optionText && eq.imageUrl"
                                                                        class="eq-img" [src]="eq.imageUrl" alt="">
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <!-- Options List -->
                                                    <h3>Options</h3>
                                                    <div class="options-container">
                                                        <div *ngFor="let option of question.options; let optionIndex = index"
                                                            class="option-item" [draggable]="true"
                                                            (dragstart)="onEquationElementDragStart($event, optionIndex, question)">
                                                            <div *ngIf="option.optionText" class="cell-item">{{
                                                                option.optionText }}
                                                            </div>
                                                            <img *ngIf="!option.optionText && option.imageUrl"
                                                                class="eq-img" [src]="option.imageUrl" alt="">
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div *ngIf="question.questionType === 'HORIZONTAL_RADIO_BUTTON'"
                                                class="d-flex align-items-stretch justify-content-between">
                                                <div *ngFor="let option of question.options"
                                                    class="d-flex flex-column-reverse justify-content-center align-items-center">
                                                    <input type="checkbox" [name]="question.id" [value]="option.id"
                                                        (change)="toggleOption(question.id, option.id)" />
                                                    <label>{{ option.optionText }}</label>
                                                </div>
                                            </div>

                                            <div *ngIf="question.questionType === 'TEXT'">
                                                <input type="text" class="form-control" formControlName="textAnswer" />
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="submit-btn">
                                    <button class="btn btn-primary" (click)="onSubmit()"> Submit</button>
                                </div> -->
                                </form>
                            </div>

                            <div *ngIf="hasQuizResult() && quizQuestions.isComplete===true" class="quiz-container">
                                <!-- <p>{{ quizResult | json }}</p> -->
                                <div *ngIf="quizResult.isComplete">
                                    <p>Already Completed</p>
                                </div>
                                <div *ngIf="quizResult.passed">
                                    <p>Congratulations! You passed the quiz with a score of {{ quizResult.score }}.</p>
                                </div>
                                <div *ngIf="!quizResult.passed">
                                    <p>Unfortunately, you did not pass. Your score is {{ quizResult.score }}.</p>
                                </div>



                                <div *ngFor="let submittedQuestion of quizResult.questionResults; let i = index">
                                    <p><b>Question no. {{i + 1}}: {{ submittedQuestion.questionText }}</b></p>
                                    <p *ngIf="submittedQuestion?.userAnswer?.replaceAll(',','')?.replaceAll(' ','')">
                                        Your
                                        Answer: {{
                                        submittedQuestion.userAnswer }}</p>


                                    <!-- <div class="grid-container"
                                        *ngIf="submittedQuestion.question.questionType === 'DRAG_AND_DROP_MATRIX'">
                                        <div *ngFor="let row of cellData; let rowIndex = index" class="grid-row">
                                            <div class="grid-cell">
                                                {{ submittedQuestion.question.matrixConfiguration?.rowLabels[rowIndex]
                                                }}
                                            </div>
                                            <div *ngFor="let cell of row; let colIndex = index" class="grid-cell">
                                                <ng-container *ngIf="cell">
                                                    <div *ngIf="cell.optionText" class="cell-item">
                                                        {{ cell.optionText }}
                                                    </div>
                                                    <img *ngIf="!cell.optionText && cell.imageUrl"
                                                        class="example-box seq-img" [src]="cell.imageUrl" alt="">
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="example-container"
                                        *ngIf="submittedQuestion.question.questionType === 'DRAG_AND_DROP_SEQUENCE'">
                                        <h2>Correct Sequence</h2>
                                        <div class="example-list border">
                                            <ng-container *ngFor="let item of submittedQuestion.question.options">
                                                <div *ngIf="item.optionText" class="example-box" class="p-4 border">
                                                    {{item.optionText}}</div>
                                                <img *ngIf="!item.optionText" class="example-box" class="seq-img"
                                                    [src]="item.imageUrl" alt="">
                                            </ng-container>
                                        </div>
                                    </div>

                                    <p *ngIf="submittedQuestion.correct"><b>Status: Correct</b></p>
                                    <p *ngIf="!submittedQuestion.correct"><b>Status: Incorrect</b></p>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="showSurvey">
                            <ng-container *ngFor="let item of survey">
                                <div class="survey-container">
                                    <h1>{{ item.title }}</h1>
                                    <p class="text-start" [innerHTML]="item.description"></p>

                                    <div *ngFor="let question of item.questions">
                                        <h3>{{ question.questionText }}</h3>
                                        <div *ngIf="question.questionType === 'SINGLE'">
                                            <div class="text-start" *ngFor="let option of question.options">
                                                <input type="radio" [name]="question.id" [value]="option.id"
                                                    [(ngModel)]="question.check" />
                                                <label>{{ option.optionText }}</label>
                                            </div>
                                        </div>
                                        <div *ngIf="question.questionType === 'HORIZONTAL_RADIO_BUTTON'">
                                            <div class="text-start" *ngFor="let option of question.options">
                                                <input type="radio" [name]="question.id" [value]="option.id"
                                                    [(ngModel)]="option.check" />
                                                <label>{{ option.optionText }}</label>
                                            </div>
                                        </div>
                                        <div *ngIf="question.questionType === 'DRAG_AND_DROP_SEQUENCE'">
                                            <div class="text-start" *ngFor="let option of question.options">
                                                {{option.optionText}}
                                                <!-- <input type="radio" [name]="question.id" [value]="option.id"
                                                    [(ngModel)]="option.check" />
                                                <label>{{ option.optionText }}</label> -->
                                            </div>
                                        </div>

                                        <div *ngIf="question.questionType === 'MULTIPLE'">
                                            <div class="text-start" *ngFor="let option of question.options">
                                                <input type="checkbox" [name]="question.id" [value]="option.id"
                                                    [(ngModel)]="option.check" />
                                                <label>{{ option.optionText }}</label>
                                            </div>
                                        </div>

                                        <div *ngIf="question.questionType === 'TEXT'">
                                            <input type="text" class="form-control" [(ngModel)]="question.text" />
                                        </div>
                                    </div>

                                    <!-- <div class="submit-btn">
                                        <button class="btn btn-primary" (click)="submitSurvey()">Submit</button>
                                    </div> -->
                                </div>

                            </ng-container>
                            <div class="survey-container">
                                <div>
                                    <h3>Tutor Rating</h3>
                                    <div class="rating-group">
                                        <label *ngFor="let rating of [1, 2, 3, 4, 5]">
                                            <input type="radio" name="tutorRating" [value]="rating"
                                                [(ngModel)]="tutorRating">
                                            {{ rating }}
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <h3>Course Rating</h3>
                                    <div class="rating-group">
                                        <label *ngFor="let rating of [1, 2, 3, 4, 5]">
                                            <input type="radio" name="courseRating" [value]="rating"
                                                [(ngModel)]="courseRating">
                                            {{ rating }}
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <div class="video-nav" *ngIf="role === 'USER'">
                            <div class="arrows d-flex justify-content-end">
                                <button class="btn bg-hover  equal-size d-flex justify-content-end "
                                    (click)="prevDoc()">
                                    <img src="../../../assets/images/Frame (3).png" /> Previous
                                </button>

                                <!-- Next button for all documents except the last one -->
                                <button *ngIf="!isLastDocument() && (!hasquizQuestions() || hasQuizResult())"
                                    class="btn bg-hover equal-size d-flex justify-content-end" (click)="nextDoc()">
                                    Next
                                    <img src="../../../assets/images/Frame (1).png" />
                                </button>

                                <!-- Submit button for quiz (if not completed) -->
                                <button *ngIf="hasquizQuestions() && quizQuestions.isComplete !== true && !showSurvey"
                                    class="btn bg-hover equal-size d-flex justify-content-end" (click)="onSubmit()">
                                    Submit
                                    <img src="../../../assets/images/Frame (1).png" />
                                </button>

                                <!-- Completed button only for the last document if the course is completed -->
                                <button *ngIf="isLastDocument() && getLastCourseDetail() && !showSurvey"
                                    class="btn bg-hover equal-size d-flex justify-content-end"
                                    (click)="completedMarkedCourse();">
                                    Survey
                                    <img src="../../../assets/images/Frame (1).png" />
                                </button>

                                <button *ngIf="showSurvey" class="btn bg-hover equal-size d-flex justify-content-end"
                                    (click)="submitSurvey();">
                                    Complete
                                    <img src="../../../assets/images/Frame (1).png" />
                                </button>
                                <!-- <button *ngIf="showSurvey" class="btn bg-hover equal-size d-flex justify-content-end"
                                    (click)="submitSurvey();" [disabled]="!isSurveyValid()">
                                    Complete
                                    <img src="../../../assets/images/Frame (1).png" />
                                </button> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="main-desc-container ">
            <div class="main-description">
                <h2>Description </h2>
                <p class="description">
                    <span [innerHTML]="selectedItemToShow?.documentDescription"></span>
                    <br />
                    <span [innerHTML]="selectedItemToShow?.sectionResponse?.sectionDescription"></span>
                    <br /><br />
                </p>


            </div>

        </div>
    </div>
</div>
<div class="lecture_comments">
    <div class="video-heading">
        <h1>Chat</h1>
    </div>
    <div class="chat_input">
        <input [(ngModel)]="comment" type="text" placeholder="Type your message here" />
        <button class="btn btn-primary d-flex align-items-center" (click)="addComment()"
            [disabled]="commentSpinner || !comment">
            <span *ngIf="commentSpinner" class="spinner-border spinner-border-sm me-2" role="status "
                aria-hidden="true"></span>
            <span>{{commentSpinner ? 'Sending...':'Send'}}</span>
        </button>
    </div>
    <div class="chat_box">
        <ng-container *ngFor="let chat of commentList">
            <div class="chat_msg">
                <div class="chat_user">
                    <span>{{chat.commentedByFullName}}</span>
                    <img *ngIf="role !== 'USER'"
                        [src]="chat.isPinned ? '../../../assets/images/pinned.png' : '../../../assets/images/unpinned.png'"
                        alt="" (click)="updateCommentPin(chat)" />
                    <div class="chip" *ngIf="chat.isPinned">Pinned</div>
                    <div class="time">{{chat.formatedDate}}</div>
                </div>
                <div class="chat_text">
                    <span>{{chat.text}}</span>
                </div>
            </div>
        </ng-container>
        <div class="no-record">
            <div *ngIf="commentList.length <= 0 && !commentsLoader" class="name">No Messages Found</div>
            <div *ngIf="commentsLoader" class="name"><span class="spinner-border spinner-border-sm me-2" role="status "
                    aria-hidden="true"></span> Loading...</div>
        </div>
    </div>
    <div class="pagination-container">

        <ngb-pagination [collectionSize]="totalComments" [(page)]="commentPage" [pageSize]="commentSize"
            (pageChange)="getComments()">
        </ngb-pagination>
    </div>
</div>