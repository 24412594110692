<div class="course-info">
    <div class="course-modal-header">
        <!-- <img [src]="tutor.pictureLink" alt=""> -->
        <div class="close-btn" (click)="close()">
            <img src="../../../../assets/images/close-modal.png" alt="close-btn">
        </div>
    </div>
    <div class="course-modal-body">
        <div class="modal-section">
            <div class="course-introduction">
                <div class="course-info-title">
                    <h2>{{ "Tutor Profile" | titlecase }}</h2>
                    <!-- <p [innerHTML]="tutor.courseDescription"></p> -->
                </div>
                <div class="course-tutor">
                    <img [src]="tutor.pictureLink" (error)="tutor.pictureLink = '../../../../assets/images/3.png'"
                        alt="">
                    <div class="d-flex flex-column">
                        <div class="tutor-name">
                            {{ (tutor?.firstName | titlecase)|| "-" + " " + (tutor?.lastName | titlecase) || "-" }}
                        </div>
                        <h6>{{tutor.username}}</h6>
                    </div>

                </div>
            </div>
            <div class="social-icons">

                <!-- <div class="social-containers">
                    <img src="../../../../assets/images/contacts.png" alt="">
                    <div class="labels">
                        <label class="level" for="text">Enrolled Students</label>
                        <label for="number">{{course.enrollment || 0}}</label>
                    </div>
                </div> -->
                <div class="social-containers">
                    <div style="  width: 50px; height: 50px;background-color: #0075ab;border-radius: 12px;padding: 6px">
                        <img style=" width: 40px; height: 40px;" src="../../../../assets/images/icons8-email-48 (1).png"
                            alt="">
                    </div>


                    <div class="labels">
                        <label class="level" for="text">Email</label>
                        <label for="number">{{tutor.email || "-"}}</label>
                    </div>
                </div>
                <div class="social-containers">
                    <div style="  width: 50px; height: 50px;background-color: #0075ab;border-radius: 12px;padding: 6px">
                        <img style=" width: 40px; height: 40px;"
                            src="../../../../assets/images/icons8-phone-number-50 (2).png" alt="">
                    </div>

                    <div class="labels">
                        <label class="level" for="text">Contact NO</label>
                        <label for="number">{{tutor.phoneNumber || "-"}}</label>
                    </div>
                </div>

            </div>

        </div>

        <div class="kpis">
            <div class="kpi w-100">
                <label for="learners">Total Enrolled Count</label>
                <h3>{{metricsData?.totalEnrolledCount || 0}}</h3>
            </div>
        </div>
        <div class="kpis">
            <div class="kpi">
                <label for="learners">Paid Enrolled Count</label>
                <h3>{{metricsData?.paidEnrolledCount || 0}}</h3>
            </div>
            <div class="kpi">
                <label for="courses">Unpaid Enrolled Count
                </label>
                <h3>{{metricsData?.unpaidEnrolledCount || 0}}</h3>
            </div>
        </div>
        <div class="modals-section" *ngIf="!loader">
            <div class="course-introduction">
                <div class="course-info-title">
                    <h2>{{ "Tutor Courses" | titlecase }}</h2>
                    <!-- <p [innerHTML]="tutor.courseDescription"></p> -->
                </div>
                <div class="course-tutor" *ngFor="let item of courses">
                    <img [src]="tutor.pictureLink" (error)="tutor.pictureLink = '../../../../assets/images/3.png'"
                        alt="img">
                    <div class="d-flex justify-content-around flex-column ">
                        <div class="tutor-name">{{item.courseName| titlecase}}</div>
                        <div class="tutor-name">{{item.level.replaceAll('_', ' ')| titlecase}}</div>
                    </div>

                </div>

                <div *ngIf="courses.length <= 0" class="name d-flex justify-content-center align-items-center">
                    Course not available
                </div>
            </div>



        </div>
        <!-- <div class="d-flex justify-content-end align-items-center w-100 pb-2 pt-2">
            <select class="form-control w-50" [(ngModel)]="course" (change)="getYearlyCourseRating()">
                <option value="" disabled selected>Select a course</option>
                <option *ngFor="let course of courses" [value]="course.id">
                    {{ course.courseName }}
                </option>
            </select>

        </div>
        <div class="charts" *ngIf="yearlyCourseRating">
            <div>
                <apx-chart [series]="yearlyCourseRating.series || []"
                    [chart]="yearlyCourseRating.chart || { type: 'bar', height: 350 }"
                    [xaxis]="yearlyCourseRating.xaxis || { categories: [] }"
                    [title]="yearlyCourseRating.title || { text: 'Enrolled count' }"
                    [fill]="yearlyCourseRating.fill||{}" [plotOptions]="yearlyCourseRating.plotOptions||{}">
                </apx-chart>
            </div>
        </div> -->
        <!-- <ng-container *ngFor="let item of selectedCourse?.learningOutcomes">
            <div class="charts" *ngIf="item.show">
                <div>
                    <apx-chart [series]="item.chartSeries || []"
                        [chart]="item.chartOptions || { type: 'bar', height: 350 }"
                        [xaxis]="item.xAxisOptions || { categories: [] }" [yaxis]="item.yAxisOptions"
                        [title]="{ text: item.keyword }" [plotOptions]="item.plotOptions || {}">
                    </apx-chart>

                </div>
            </div>
        </ng-container> -->


        <div *ngIf="loader" class="name d-flex justify-content-center align-items-center mt-4">
            <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading...
        </div>
    </div>
</div>