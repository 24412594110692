<div class="card">
  <div class="card-header">
    <div class="modal-heading">
      <img src="./assets/images/add-icon.png" alt="">
      <span>Add Category</span>
    </div>
    <div>
      <div class="close">
        <img (click)="close()" src="./assets/images/close-modal.png" alt="">
      </div>
    </div>
  </div>
  <div class="card-body">
    <form #form="ngForm" autocomplete="off" (ngSubmit)="submit()">
      <div class="form-group">
        <label for="categoryName">Category Name</label>
        <input class="form-control" type="text" id="categoryName" [(ngModel)]="categoryName" name="categoryName"
          placeholder="Enter category name" required>
        <!-- (input)="onCategoryNameChange($event)" -->
      </div>

      <div class="form-group">
        <label for="categoryImage">Category Image</label>
        <input class="form-control" type="file" (change)="onFileSelected($event)" accept="image/*">
      </div>

      <div class="form-group">
        <div class="d-flex align-items-center justify-content-between gap-1 w-100 pb-2">
          <label for=" subcategories">Subcategories </label> <span class="add-more" (click)="addSubcategory()">+</span>
        </div>
        <ng-container *ngFor="let subcategory of subcategories; let i = index; trackBy: trackByFn">
          <div class="input-group mb-2">
            <div class="d-flex align-items-center justify-content-center gap-1 w-100">
              <input type="text" class="form-control" [(ngModel)]="subcategories[i]" [name]="'subcategory'+i"
                placeholder="Enter subcategory name" required>
              <!-- [(ngModel)]="subcategories[i]" [name]="'subcategory'" -->
              <!-- <button type="button" class="btn btn-danger" (click)="removeSubcategory(i)">Remove</button> -->
              <div class="remove-sub" (click)="removeSubcategory(i)">x</div>
            </div>
          </div>
        </ng-container>

      </div>


      <div class="w-100 d-flex align-items-center justify-content-end gap-1">
        <button type="submit" class="btn btn-primary btn-primary-add " [disabled]="spinner">
          <span *ngIf="spinner" class="spinner-border spinner-border-sm me-2" role="status " aria-hidden="true"></span>
          {{spinner ? 'Submiting.....':'submit'}}
        </button>
      </div>
    </form>
  </div>
</div>