<div class="header">
    <div>
        <p>Page Management</p>
        <span>{{pageName | uppercase}}</span>
    </div>
</div>

<div class="d-flex justify-content-center">
    <div *ngIf="pageData.length <= 0 && !spinner" class="name">No Record Found</div>
    <div *ngIf="spinner" class="name"><span class="spinner-border spinner-border-sm me-2" role="status"
            aria-hidden="true"></span> Loading...</div>
</div>

<div class="page-container">
    <ng-container *ngFor="let item of pageData; let i = index">
        <div class="page-body" *ngIf="blocksList[item.blockName] && !blocksList[item.blockName].sub"
            [class.w-50]="blocksList[item.blockName]?.subSections">
            <div class="section">
                <div class="section-header">
                    <h4>{{blocksList[item.blockName].alias || item.blockName}}</h4>
                    <div class="section-action">
                        <div class="form-check form-switch">
                            <input *ngIf="blocksList[item.blockName].isActive" class=" form-check-input" type="checkbox"
                                [checked]="item.isActive" (change)="toggleIsBlock(item)">
                            <label class="form-check-label" [ngClass]="{ 'active': item.isActive }"></label>
                        </div>
                        <img [src]="item.dropdown ? '../../../assets/images/up-Vector.png' : '../../../assets/images/Vector.png' "
                            class="click" (click)="item.dropdown = !item.dropdown" alt="">
                    </div>
                </div>
                <div class="section-body" *ngIf="item.dropdown">
                    <form action="" #myForm="ngForm">
                        <div *ngIf="blocksList[item.blockName].title">
                            <label for="">Heading</label>
                            <input [(ngModel)]="item.heading" type="text" [name]="'heading'+i"
                                placeholder="Begin. Byild. Be.">
                        </div>
                        <div *ngIf="blocksList[item.blockName].description">
                            <label for="">Description</label>
                            <textarea type=" text" [(ngModel)]="item.description"
                                [maxlength]="blocksList[item.blockName]?.descriptionMaxLimit || '500'"
                                [name]="'description'+i" placeholder="Embark on your educational journey with BYILD."
                                style="resize: none;" #description="ngModel" rows="5"></textarea>
                        </div>
                        <div class="field__inline field__img" *ngIf="blocksList[item.blockName].img">
                            <label for="CompanyLogo">Image</label>
                            <div class="image-drop">
                                <input type="file" (change)="onFileSelected($event, item)" accept="image/*" hidden
                                    #fileInput>
                                <div *ngIf="!item.imageUrl" class="container-1" (dragover)="onDragOver($event)"
                                    (drop)="onDrop($event, item)">
                                    <img class="upload-icon" src="../../../../assets/images/gallery-add.png" />
                                    <p class="upload-text">Drag or Drop image here</p>
                                    <p class="upload-text">or</p>
                                    <button class="btn btn-outline-primary" (click)="fileInput.click()">Browse
                                        Files</button>
                                </div>
                                <img class="preview" *ngIf="item.imageUrl" (click)="fileInput.click()"
                                    [src]="item.imageUrl" alt="Uploaded Image">
                                <div *ngIf="item.imageUrl" class="setting-edit" (click)="fileInput.click()">
                                    <img src="../../../assets//images/setting-edit.png" alt="">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="d-flex justify-content-end align-items-center rameez gap-1 m-4">
    <button type="button" class="btn btn-primary btn-primary-add" (click)="submit()" [disabled]="btnspinner">
        <span *ngIf="btnspinner" class="spinner-border spinner-border-sm me-2" role="status " aria-hidden="true"></span>
        {{btnspinner ? 'Publishing...':'Publish Changes'}}
    </button>
</div>