import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class PathwayFormService {

  constructor(private http: HttpClient) { }


  getAllProgram(): Observable<any> {
    return this.http.get(environment.apiUrl + `/program?pageNo=0&pageSize=10`)
  }

  
  addPathway(payload: any): Observable<any> {
    return this.http.post(environment.apiUrl + `/pathway`, payload)
  }


  submitPathway(payload: any): Observable<any> {
    return this.http.post(environment.apiUrl + `/pathway/add-program`, payload)
  }
}
