import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PortalHeaderComponent } from './portal-header/portal-header.component';
import { RouterModule } from '@angular/router';
import { LectureComponent } from './lecture/lecture.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ArticlePreviewComponent } from './article-preview/article-preview.component';
import { PageManagementTemplateComponent } from './page-management-template/page-management-template.component';



@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    PortalHeaderComponent,
    LectureComponent,
    ArticlePreviewComponent,
    PageManagementTemplateComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDocViewerModule,
    DragDropModule,
    NgbPaginationModule
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    PortalHeaderComponent,
    LectureComponent,
    ArticlePreviewComponent,
    PageManagementTemplateComponent
  ]
})
export class LayoutComponentsModule { }
