import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { CommunityService } from 'src/app/main/community-main/community.service';
import Swal from 'sweetalert2';
import { PageManagementTemplateService } from './page-management-template.service';

@Component({
  selector: 'app-page-management-template',
  templateUrl: './page-management-template.component.html',
  styleUrls: ['./page-management-template.component.scss']
})
export class PageManagementTemplateComponent {
  isBlock: boolean = false;
  disable: boolean = true;


  imageUrl: string | ArrayBuffer | null = '';
  profileUrl!: any;

  logo: any;
  profile: any;


  Url: string | null = null;
  @Input() pageName: string | null = null;
  pageData: any[] = []
  spinner: boolean = false;
  btnspinner: boolean = false;
  constructor(private service: PageManagementTemplateService, private route: ActivatedRoute, private router: Router) {
  }
  blocksList: any = {
    "Hero Section": { img: true, title: true, description: true, isActive: false, sub: true },
    "Research And Development": { img: false, title: true, description: true, isActive: true },
    "Gallery": { img: false, title: true, description: true, isActive: true },
    "Events": { img: false, title: true, description: true, isActive: true },
    "Blogs": { img: false, title: true, description: true, isActive: true },
    "Pathways": { img: false, title: true, description: true, isActive: true },
    "Popular Courses": { img: false, title: true, description: false, isActive: true },
    "Plans": { img: true, title: true, description: false, isActive: true },
    "Plan Block 1": { img: true, title: false, description: true, isActive: true, alias: 'Plan Block 1', subSections: true, descriptionMaxLimit: 130 },
    "Plan Block 2": { img: true, title: false, description: true, isActive: true, alias: 'Plan Block 2', subSections: true, descriptionMaxLimit: 130 },
    "Plan Block 3": { img: true, title: false, description: true, isActive: true, alias: 'Plan Block 3', subSections: true, descriptionMaxLimit: 130 },
    "Plan Block 4": { img: true, title: false, description: true, isActive: true, alias: 'Plan Block 4', subSections: true, descriptionMaxLimit: 130 },
    "Why Learner Choose Us": { img: false, title: true, description: false, isActive: true },
    "Why Learner Choose Us 1": { img: true, title: false, description: true, isActive: true, alias: 'Why Learner Choose Us 1', subSections: true, descriptionMaxLimit: 130 },
    "Why Learner Choose Us 2": { img: true, title: false, description: true, isActive: true, alias: 'Why Learner Choose Us 2', subSections: true, descriptionMaxLimit: 130 },
    "Why Learner Choose Us 3": { img: true, title: false, description: true, isActive: true, alias: 'Why Learner Choose Us 3', subSections: true, descriptionMaxLimit: 130 },
    "Why Learner Choose Us 4": { img: true, title: false, description: true, isActive: true, alias: 'Why Learner Choose Us 4', subSections: true, descriptionMaxLimit: 130 },
    "Our Tutors": { img: false, title: true, description: false, isActive: true },
    "Awesome in 60": { img: false, title: true, description: true, isActive: true },
    "Free Courses": { img: false, title: true, description: true, isActive: true },
    "Subject": { img: false, title: true, description: false, isActive: true },
    "Programs": { img: false, title: true, description: true, isActive: true },
    "Learn a new skill": { img: false, title: true, description: false, isActive: true },
    "Learn something new for fun": { img: false, title: true, description: true, isActive: true },
    "Popular Pathways": { img: false, title: true, description: true, isActive: true },
    "Top Ranking Course": { img: false, title: true, description: true, isActive: true },
    "Create Unique Pathway": { img: false, title: true, description: true, isActive: true },
    "4-week subscription": { img: false, title: true, description: true, isActive: true },
    "12-week subscription": { img: false, title: true, description: true, isActive: true },
    "24-week subscription": { img: false, title: true, description: true, isActive: true },
    "52-week subscription": { img: false, title: true, description: true, isActive: true },
    "Hero": { img: true, title: true, description: true, isActive: false },
    "Banner": { img: false, title: true, description: false, isActive: false, alias: 'Why contact us' },
    "wwb-sub-1": { img: true, title: false, description: true, isActive: false, alias: 'Why contact us Block 1', subSections: true, descriptionMaxLimit: 130 },
    "wwb-sub-2": { img: true, title: false, description: true, isActive: false, alias: 'Why contact us Block 2', subSections: true, descriptionMaxLimit: 130 },
    "wwb-sub-3": { img: true, title: false, description: true, isActive: false, alias: 'Why contact us Block 3', subSections: true, descriptionMaxLimit: 130 },
    "wwb-sub-4": { img: true, title: false, description: true, isActive: false, alias: 'Why contact us Block 4', subSections: true, descriptionMaxLimit: 130 },
    "Why we Byild": { img: false, title: true, description: true, isActive: true },
    "au-wwb-sub-1": { img: true, title: false, description: true, isActive: true, alias: 'Why we Byild Block 1', subSections: true, descriptionMaxLimit: 130 },
    "au-wwb-sub-2": { img: true, title: false, description: true, isActive: true, alias: 'Why we Byild Block 2', subSections: true, descriptionMaxLimit: 130 },
    "au-wwb-sub-3": { img: true, title: false, description: true, isActive: true, alias: 'Why we Byild Block 3', subSections: true, descriptionMaxLimit: 130 },
    "au-wwb-sub-4": { img: true, title: false, description: true, isActive: true, alias: 'Why we Byild Block 4', subSections: true, descriptionMaxLimit: 130 },
    "Why you should Byild": { img: true, title: true, description: false, isActive: true },
    "au-wysb-sub-1": { img: true, title: false, description: true, isActive: true, alias: 'Why you should Byild Block 1', subSections: true, descriptionMaxLimit: 130 },
    "au-wysb-sub-2": { img: true, title: false, description: true, isActive: true, alias: 'Why you should Byild Block 2', subSections: true, descriptionMaxLimit: 130 },
    "au-wysb-sub-3": { img: true, title: false, description: true, isActive: true, alias: 'Why you should Byild Block 3', subSections: true, descriptionMaxLimit: 130 },
    "au-wysb-sub-4": { img: true, title: false, description: true, isActive: true, alias: 'Why you should Byild Block 4', subSections: true, descriptionMaxLimit: 130 },
    "What we are made of": { img: false, title: true, description: false, isActive: true },
    "au-wam-sub-1": { img: true, title: false, description: true, isActive: true, alias: 'What we are made of Block 1', subSections: true, descriptionMaxLimit: 130 },
    "au-wam-sub-2": { img: true, title: false, description: true, isActive: true, alias: 'What we are made of Block 2', subSections: true, descriptionMaxLimit: 130 },
    "au-wam-sub-3": { img: true, title: false, description: true, isActive: true, alias: 'What we are made of Block 3', subSections: true, descriptionMaxLimit: 130 },
    "au-wam-sub-4": { img: true, title: false, description: true, isActive: true, alias: 'What we are made of Block 4', subSections: true, descriptionMaxLimit: 130 },
    "Main Features": { img: true, title: true, description: false, isActive: true },
    "Terms and Conditions": { img: false, title: true, description: true, isActive: true },
    "All Level - 1 Courses": { img: false, title: true, description: true, isActive: true, alias: 'Courses' },
    "Some Other Thing": { img: false, title: true, description: true, isActive: true },
    "Some Other Thing 1": { img: true, title: false, description: true, isActive: true, alias: 'Some Other Thing Block 1', subSections: true, descriptionMaxLimit: 130 },
    "Some Other Thing 2": { img: true, title: false, description: true, isActive: true, alias: 'Some Other Thing Block 2', subSections: true, descriptionMaxLimit: 130 },
    "Some Other Thing 3": { img: true, title: false, description: true, isActive: true, alias: 'Some Other Thing Block 3', subSections: true, descriptionMaxLimit: 130 },
    "Some Other Thing 4": { img: true, title: false, description: true, isActive: true, alias: 'Some Other Thing Block 4', subSections: true, descriptionMaxLimit: 130 },
    "Plans 1": { img: true, title: false, description: true, isActive: true, alias: 'Plan Block 1', subSections: true, descriptionMaxLimit: 130 },
    "Plans 2": { img: true, title: false, description: true, isActive: true, alias: 'Plan Block 2', subSections: true, descriptionMaxLimit: 130 },
    "Plans 3": { img: true, title: false, description: true, isActive: true, alias: 'Plan Block 3', subSections: true, descriptionMaxLimit: 130 },
    "Plans 4": { img: true, title: false, description: true, isActive: true, alias: 'Plan Block 4', subSections: true, descriptionMaxLimit: 130 },
    "Who we are?": { img: false, title: true, description: true, isActive: true },
    "Account Registration": { img: false, title: true, description: true, isActive: true },
    "Courses and Programs": { img: false, title: true, description: true, isActive: true },
    "Notifications": { img: false, title: true, description: true, isActive: true },
    "User Conduct": { img: false, title: true, description: true, isActive: true },
    "Content and Intellectual Property": { img: false, title: true, description: true, isActive: true },
    "Tutor Obligations": { img: false, title: true, description: true, isActive: true },
    "Relationships to Other Users": { img: false, title: true, description: true, isActive: true },
    "Security Measures": { img: false, title: true, description: true, isActive: true },
    "Payment Methods and Processing": { img: false, title: true, description: true, isActive: true },
    "Pay-per-Course/Program Payment Terms": { img: false, title: true, description: true, isActive: true },
    "Subscription Payment Terms": { img: false, title: true, description: true, isActive: true },
    "Discount and Promotional Offers": { img: false, title: true, description: true, isActive: true },
    "Payment Failures and Disputes": { img: false, title: true, description: true, isActive: true },
    "Security and Data Privacy": { img: false, title: true, description: true, isActive: true },
    "Deleting Your Account": { img: false, title: true, description: true, isActive: true },
    "Modifications to the Terms and Conditions": { img: false, title: true, description: true, isActive: true },
    "Termination": { img: false, title: true, description: true, isActive: true },
    "Main Section": { img: true, title: true, description: true, isActive: false },
    "Plan 1 Main Features": { img: true, title: false, description: true, isActive: true, alias: 'Monthly Plan' },
    "Plan 1 Ponit 1": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Monthly Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 1 Ponit 2": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Monthly Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 1 Ponit 3": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Monthly Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 1 Ponit 4": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Monthly Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 1 Terms and Conditions": { img: false, title: false, description: true, isActive: true, alias: 'Monthly Plan Terms and Conditions' },
    "Plan 2 Main Features": { img: true, title: false, description: true, isActive: true, alias: 'Quarterly Plan' },
    "Plan 2 Ponit 1": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Quarterly Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 2 Ponit 2": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Quarterly Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 2 Ponit 3": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Quarterly Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 2 Ponit 4": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Quarterly Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 2 Terms and Conditions": { img: false, title: false, description: true, isActive: true, alias: 'Quarterly Plan Terms and Conditions' },
    "Plan 3 Main Features": { img: true, title: false, description: true, isActive: true, alias: 'Half Year Plan' },
    "Plan 3 Ponit 1": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Half Year Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 3 Ponit 2": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Half Year Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 3 Ponit 3": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Half Year Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 3 Ponit 4": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Half Year Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 3 Terms and Conditions": { img: false, title: true, description: true, isActive: true, alias: 'Half Year Plan Terms and Conditions' },
    "Plan 4 Main Features": { img: true, title: false, description: true, isActive: true, alias: 'Yearly Plan' },
    "Plan 4 Ponit 1": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Yearly Plan Point 1', descriptionMaxLimit: 130 },
    "Plan 4 Ponit 2": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Yearly Plan Point 2', descriptionMaxLimit: 130 },
    "Plan 4 Ponit 3": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Yearly Plan Point 3', descriptionMaxLimit: 130 },
    "Plan 4 Ponit 4": { img: false, title: false, description: true, isActive: true, subSections: true, alias: 'Yearly Plan Point 4', descriptionMaxLimit: 130 },
    "Plan 4 Terms and Conditions": { img: false, title: false, description: true, isActive: true, alias: 'Yearly Plan Terms and Conditions' },
  }
  ngOnInit() {
    this.spinner = true;
    this.route.url.subscribe(() => {
      this.Url = this.router.url;
      this.pageName = this.Url.split('/')[3] || null;
      if (this.Url.split('/')[3] !== 'Content-filter') {
        this.pageName = this.Url.split('/')[3].replaceAll('-', ' ') || null;
      }
      this.getBlockData();
    });
  }

  getBlockData() {
    let payload = {
      pageName: this.pageName
    }
    lastValueFrom(this.service.getPageByName(payload)).then(res => {
      this.spinner = false;
      if (res) {
        console.log(res.data.blocks);
        this.pageData = res.data.blocks
        console.log(this.pageData);
        this.pageData.forEach((element: any) => {
          element.description = element.content.description || ''
          element.heading = element.content.title || ''
          element.image = element.content.image || ''
          element.dropdown = element.content.dropdown || true
          element.imageUrl = element.content.image || ''
          element.contentId = element.content.id
        })
      }
    })
  }


  toggleIsBlock(item: any): void {
    item.isActive = !item.isActive; // Toggle the isActive status

    let payload = {
      status: item.isActive, // Send the updated isActive status
      block: item.blockId,
      page: this.pageName
    };
    console.log(payload);

    lastValueFrom(this.service.sectionStatus(payload)).then(res => {
      if (res) {
        // console.log(res);
        // this.getBlockData();
      }
    }).catch(err => {
      item.isActive = !item.isActive;
      console.log(err)
    });
  }


  private handleFile(file: File | undefined, item: any): void {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        item.imageUrl = reader.result;
        item.image = file;
      };
      reader.readAsDataURL(file);
    }
  }

  onFileSelected(event: Event, item: string): void {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const fileType = file?.type;
    this.handleFile(file, item);
  }


  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent, item: any): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    this.handleFile(file, 'logo');
  }


  submit() {
    this.btnspinner = true;
    let fd = new FormData();
    this.pageData.forEach((element: any, index: any) => {
      fd.append('blockContents[' + index + '].contentId', element.contentId)
      fd.append('blockContents[' + index + '].sectionId', element.blockId)
      fd.append('blockContents[' + index + '].title', element.heading)
      fd.append('blockContents[' + index + '].description', element.description)
      if (typeof (element.image) !== 'string') {
        fd.append('blockContents[' + index + '].image', element.image)
      }
    });
    lastValueFrom(this.service.addContent(fd)).then(res => {

      if (res.success) {
        this.btnspinner = false;
        Swal.fire({
          title: 'Success',
          text: 'Content added successfully',
          icon: 'success',
          confirmButtonColor: '#0075AB',
        })

        // this.getBlockData();
      }
      else {
        this.btnspinner = false
        Swal.fire({
          title: 'Error',
          text: 'Something went wrong',
          icon: 'error',
          confirmButtonColor: '#0075AB',
        })

      }
    }, (error) => {
      this.btnspinner = false

    })
  }
}