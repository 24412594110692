import { Component, QueryList, ViewChildren } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { LecturesService } from 'src/app/main/lectures/lectures.service';
import Swal from 'sweetalert2';


import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CommentsService } from '../../Shared-Service/comments.service';
@Component({
  selector: 'app-lecture-shared',
  templateUrl: './lecture.component.html',
  styleUrls: ['./lecture.component.scss']
})
export class LectureComponent {

  courseId: any;

  selectedData: any
  selectedItem: any;
  courseRating = 0;
  tutorRating = 0;
  courseDetails: any[] = []
  selectedItemToShow: any = {};
  documents: any = []

  quizQuestions: any = {}

  quizId = ''
  groupedData: any[] = [];
  quizResult: any = {}
  role: any;

  constructor(
    private Service: LecturesService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private ngxspinner: NgxSpinnerService,
    private analytics: AngularFireAnalytics,
    private commentsService: CommentsService
  ) { }

  ngOnInit(): void {
    this.ngxspinner.show()
    this.activeRouter.queryParams.subscribe((params: any) => {
      if (params.id) {
        this.courseId = params.id;
        console.log(this.courseId);
        this.role = JSON.parse(localStorage.getItem('user')!).role;
        if (JSON.parse(localStorage.getItem('user')!).role === 'USER') {
          this.Service.getdocumentsEnrolled(this.courseId).subscribe(
            (res) => {
              if (res) {
                // 
                this.getSurvey()
                this.ngxspinner.hide()
                this.courseDetails = res.data
                this.courseDetails.forEach((item: any) => {

                  item.documents.forEach((element: any) => {
                    element.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + element.documentId + '?controls=1&autoplay=0&loop=0');
                  });
                });
                // this.courseDetails.forEach((item: any) => {
                //   item.documentsArray = []
                //   item.documents.forEach((doc: any) => {
                //     item.documentsArray.push({
                //       documentName: doc,
                //     })
                //   })
                // })
                // this.setSelectDocument(this.courseDetails[0], this.courseDetails[0].documentsArray[0])


                // const grouped = this.courseDetails.reduce((acc, curr) => {
                //   const sectionId = curr.id;

                //   // Find the existing section group
                //   let sectionGroup = acc.find((item: any) => item.id === sectionId);

                //   if (!sectionGroup) {
                //     // If no group exists, create a new one
                //     sectionGroup = {
                //       sectionResponse: curr.sectionResponse,
                //       documents: []
                //     };
                //     acc.push(sectionGroup);
                //   }

                //   // Push the current document to the corresponding section group
                //   sectionGroup.documents.push({
                //     id: curr.id,
                //     documentName: curr.documentName,
                //     documentId: curr.documentId,
                //     documentDescription: curr.documentDescription,
                //     documentLink: curr.documentLink,
                //     documentType: curr.documentType
                //   });

                //   return acc;
                // }, []);

                // this.courseDetails = grouped;
                console.log(this.courseDetails);


                if (this.courseDetails.length > 0) {
                  this.selectedItemToShow = this.courseDetails[0].documents[0];
                  this.getComments()
                }
                else {
                  Swal.fire('No data found', 'Please wait for data to be added', 'info')
                  this.router.navigate(['/main'])
                }
              } else {
                Swal.fire('No data found', 'Please wait for data to be added', 'info')
                this.router.navigate(['/main'])
                this.ngxspinner.hide()
              }
            },
            (error) => {
              this.ngxspinner.hide()
              Swal.fire('No data found', 'Please wait for data to be added', 'info')
              this.router.navigate(['/main'])

            }
          );
        } else {
          lastValueFrom(this.Service.getSectionWithDocuments(this.courseId)).then((res: any) => {
            if (res) {
              // 
              this.getSurvey()
              this.ngxspinner.hide()
              this.courseDetails = res.data
              this.courseDetails.forEach((item: any) => {

                item.documents.forEach((element: any) => {
                  element.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + element.documentId + '?controls=1&autoplay=0&loop=0');
                });
              });


              if (this.courseDetails.length > 0) {
                this.selectedItemToShow = this.courseDetails[0].documents[0];
                this.getComments()
              }
              else {
                Swal.fire('No data found', 'Please wait for data to be added', 'info')
                this.router.navigate(['/main'])
              }
            } else {
              Swal.fire('No data found', 'Please wait for data to be added', 'info')
              this.router.navigate(['/main'])
              this.ngxspinner.hide()
            }
          })

        }
      }
    });


  }

  dropItem(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  getSurvey() {
    this.ngxspinner.show()
    this.Service.getSurvey(this.courseId, 0, 1000).subscribe(
      (res) => {
        this.ngxspinner.hide()
        if (res) {
          this.survey = res.data.content
        }
      }
    )
  }


  timePeriods = [
    'Bronze age',
    'Iron age',
    'Middle ages',
    'Early modern period',
    'Long nineteenth century',
  ];

  dropLinear(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.timePeriods, event.previousIndex, event.currentIndex);
  }
  showSurvey = false;
  survey: any[] = [
  ]
  cellData!: any;
  async getQuizQuestions(payload: any) {
    await lastValueFrom(this.Service.getQuizbyId(payload)).then((res: any) => {
      this.quizQuestions = res.data

      this.quizQuestions.questions.forEach((question: any) => {
        if (question.questionType === "DRAG_AND_DROP_MATRIX") {
          const rowCount = question.matrixConfiguration.rowCount;
          const columnCount = question.matrixConfiguration.columnCount;
          question.targetOptions = Array.from({ length: rowCount }, () =>
            Array(columnCount).fill(null)
          );

          question.matrixConfiguration.rowLabels = JSON.parse(question.matrixConfiguration.rowLabels)
          question.matrixConfiguration.columnLabels = JSON.parse(question.matrixConfiguration.columnLabels)

          question.cellData = Array.from({ length: question.matrixConfiguration.rowCount + 1 }, () =>
            Array.from({ length: question.matrixConfiguration.columnCount + 1 }, () => [])
          );
          console.log(question.cellData)

        } else {

          question.targetOptions = []
        }
      })
      // console.log(payload)
      console.log(res.data)
      console.log(this.quizQuestions)
    })



  }


  // Variable to store the dragged option
  draggedEquationElement: any = null;

  // Store the dragged equation element
  onEquationElementDragStart(event: any, optionIndex: number, question: any) {
    this.draggedEquationElement = question.options[optionIndex]; // Get the dragged option
    event.dataTransfer.setData('text', JSON.stringify(this.draggedEquationElement)); // Store option in drag event
  }

  // Prevent default behavior to allow drop
  onEquationElementDragOver(event: any) {
    event.preventDefault();
  }

  // Handle drop of an equation element into a placeholder
  onEquationElementDrop(event: any, eqIndex: number, question: any) {
    event.preventDefault();

    if (this.draggedEquationElement && question.equationElements[eqIndex].isPlaceholder) {
      // Replace the placeholder with the dropped option
      // if (question.equationElements[eqIndex].optionText || question.equationElements[eqIndex].imageUrl) {
      //   question.options.push(question.equationElements[eqIndex]);
      // }
      if (this.draggedEquationElement.imageUrl) {
        question.equationElements[eqIndex].imageUrl = this.draggedEquationElement.imageUrl;
      } else {
        question.equationElements[eqIndex].text = this.draggedEquationElement.optionText;
        question.equationElements[eqIndex].optionText = this.draggedEquationElement.optionText;
      }
      question.equationElements[eqIndex].isPlaceholder = true; // Mark as filled
      question.equationElements[eqIndex].tempOptionId = this.draggedEquationElement.tempOptionId; // Store the option ID

      // Remove the option from the options list after dropping
      // this.removeEquationElementFromOptions(this.draggedEquationElement, question);

      // Clear the dragged option
      this.draggedEquationElement = null;
    }
  }

  // Helper function to remove the equation element from the options list
  removeEquationElementFromOptions(element: any, question: any) {
    const index = question.options.findIndex((o: any) => o.id === element.id);
    if (index > -1) {
      question.options.splice(index, 1); // Remove the option
    }
  }

  // Optionally: Move the equation element back to the options list if needed (for undo functionality)
  moveEquationElementBackToOptions(eqIndex: number, question: any) {
    const element = question.equationElements[eqIndex];
    if (element.text) {
      const movedElement = { id: Date.now(), optionText: element.text, isCorrect: false };
      question.options.push(movedElement); // Add to options
      element.text = '[:blank]'; // Reset placeholder
      element.isPlaceholder = true; // Mark as a placeholder again
    }
  }

  onDragStart(event: any, option: any) {
    event.dataTransfer.setData('text', JSON.stringify(option));
  }

  // Allow dropping by preventing the default dragover behavior
  onDragOver(event: any) {
    event.preventDefault();
  }

  // Handle the drop event, placing the option into the cell
  onDrop(event: any, rowIndex: number, colIndex: number, question: any) {
    event.preventDefault();

    // Get the dropped option from the data transfer
    const droppedOption = JSON.parse(event.dataTransfer.getData('text'));

    // Ensure that the cell is empty before placing an option
    if (!question.cellData[rowIndex][colIndex]?.length) {
      // Place the dropped option in the selected cell
      question.cellData[rowIndex][colIndex] = droppedOption;

      // Remove the option from the options list
      this.removeOptionFromList(droppedOption, question);
    }
  }

  // Helper function to remove an option from the options list
  removeOptionFromList(option: any, question: any) {
    const index = question.options.findIndex((o: any) => o.id === option.id);
    if (index > -1) {
      question.options.splice(index, 1); // Remove the option
    }
  }

  // Handle moving an option back to the options list
  moveToOptions(cell: any, rowIndex: number, colIndex: number, question: any) {
    // Ensure the cell has a valid option before proceeding
    if (cell) {
      // Add the option back to the options list
      question.options.push(cell);

      // Remove the option from the grid cell
      question.cellData[rowIndex][colIndex] = null;
    }
  }

  // Get the list of IDs for grid cells (used for setting drop targets)
  getGridDropListIds(question: any) {
    const ids = [];
    for (let i = 0; i < question.matrixConfiguration.rowCount; i++) {
      for (let j = 0; j < question.matrixConfiguration.columnCount; j++) {
        ids.push(`cell-${i}-${j}`);
      }
    }
    return ids;
  }
  dropItemInCell(event: CdkDragDrop<any[]>, col: number, row: number, question: any) {
    const cell = question.cellData[col][row];
    if (event.previousContainer === event.container) {
      return; // Do nothing for drops in the same container
    }
    if (cell.length === 0) {
      transferArrayItem(event.previousContainer.data, cell, event.previousIndex, 0);
    }
  }
  isSurveyValid(): boolean {
    return this.survey.every((item: any) =>
      item.questions.every((q: any) => {
        if (q.questionType === 'TEXT') {
          return q.textAnswer && q.textAnswer.trim() !== ''; // Text answer should not be empty
        } else if (q.questionType === 'SINGLE' || q.questionType === 'HORIZONTAL_RADIO_BUTTON') {
          return q.options.some((option: any) => option.id === q.check); // At least one radio selected
        } else if (q.questionType === 'MULTIPLE') {
          return q.options.some((option: any) => option.check); // At least one checkbox selected
        }
        return true;
      })
    );
  }


  async submitSurvey() {
    this.ngxspinner.show()
    let userAnswers: any[] = []
    console.log(this.survey)
    this.survey.forEach((question: any) => {
      question.questions.forEach((q: any) => {
        if (q.questionType === 'TEXT') {
          userAnswers.push({
            questionId: q.id,
            textAnswer: q.textAnswer
          })
        }
        else {
          userAnswers.push({
            questionId: q.id,
            optionIds: q.options.filter((option: any) => option.check).map((option: any) => option.id)
          })
        }
      })
    })
    let payload = {
      courseRating: this.courseRating,
      tutorRating: this.tutorRating,
      userAnswers: userAnswers
    }
    this.Service.submitSurvey(payload, this.survey[0]?.id).subscribe(

      async (res) => {
        this.analytics.logEvent('button_click', { button_name: 'Submit Survey' })
        this.ngxspinner.hide()
        if (res) {
          this.showSurvey = false
          await this.setSectionAsCompleted(this.selectedItemToShow);
          this.analytics.logEvent('button_click', { button_name: 'Course Completed' });
          Swal.fire('Congratulations', 'You have completed the course', 'success');
          this.router.navigate(['/main/learner/completed-courses']);

        }
      }
    )
  }
  selectedAnswers: { [key: string]: any[] } = {};

  selectOption(questionId: string, optionId: string): void {
    this.selectedAnswers[questionId] = [optionId];

  }



  // Handles option selection for checkboxes (MULTIPLE choice)
  toggleOption(questionId: string, optionId: string): void {
    if (!this.selectedAnswers[questionId]) {
      this.selectedAnswers[questionId] = [];
    }
    const index = this.selectedAnswers[questionId].indexOf(optionId);
    if (index > -1) {
      this.selectedAnswers[questionId].splice(index, 1);
    } else {
      this.selectedAnswers[questionId].push(optionId);
    }
  }

  // selectSession(item: any) {

  //   this.selectedItemToShow = item
  //   console.log(item.id)
  //   this.quizQuestions = ''
  //   this.quizResult = {}
  //   if (item.documentType === 'QUIZ') {
  //     this.quizId = item.quizId
  //     this.getQuizQuestions(item.quizId)

  //     lastValueFrom(this.Service.getQuizResult(this.quizId)).then((res: any) => {
  //       this.quizResult = res.data
  //       console.log(this.quizResult)
  //     })
  //   }

  // }
  // async nextDoc() {
  //   await this.setSectionAsCompleted(this.selectedItemToShow)
  //   this.quizQuestions = ''
  //   for (let index = 0; index < this.courseDetails.length; index++) {
  //     const item = this.courseDetails[index];

  //     for (let docIndex = 0; docIndex < item.documents.length; docIndex++) {
  //       const doc = item.documents[docIndex];
  //       console.log(item.documents)
  //       console.log(this.selectedItemToShow)
  //       if (this.selectedItemToShow === doc) {
  //         if (docIndex < item.documents.length - 1) {
  //           // If there are more documents in the current item, select the next one
  //           
  //           this.selectedItemToShow = item.documents[docIndex + 1];
  //           return;  // Exit after setting the next document
  //         } else if (index < this.courseDetails.length - 1 && this.courseDetails[index + 1].documents.length > 0) {
  //           // If the current document is the last one, switch to the next courseDetail's first document
  //           this.selectedItemToShow = this.courseDetails[index + 1].documents[0];
  //           return;  // Exit after switching to the next course's first document
  //         } else {
  //           // Optional: Handle case when there are no more courseDetails left
  //           if (this.courseDetails[this.courseDetails.length - 1].completed) {
  //             Swal.fire('Congratulations', 'You have completed the course', 'success')
  //             this.router.navigate(['/main/learner/completed-courses'])
  //           }
  //           console.log('No more course details available');
  //           return;  // Exit after reaching the last document in the last course
  //         }
  //       }
  //     }
  //   }
  // }

  selectSession(item: any) {
    this.selectedItemToShow = item;
    this.quizQuestions = {} // Resetting to an empty array if it holds questions.
    this.quizResult = {}; // Resetting quizResult to an empty object.
    this.getComments()
    if (item.documentType === 'QUIZ') {
      this.quizId = item.quizId;
      this.getQuizQuestions(item.quizId); // Ensure this method populates quizQuestions correctly.

      lastValueFrom(this.Service.getQuizResult(this.quizId))
        .then((res: any) => {
          this.quizResult = res.data; // Assuming res.data is the correct structure.
          this.quizResult.questions.forEach((question: any) => {
            question.cellData = Array.from({ length: question.matrixConfiguration.columnCount + 1 }, () =>
              Array.from({ length: question.matrixConfiguration.columnCount + 1 }, () => []))

          })
          console.log(this.quizResult);
        })
        .catch((error) => {
          console.error('Error fetching quiz result:', error);
          // Handle error if needed, e.g., reset quizResult or notify the user.
        });
    }
  }

  defaultPinImage(isPinned: boolean): string {
    return isPinned
      ? '../../../assets/images/pinned.png'
      : '../../../assets/images/unpinned.png';
  }

  hoverPinImage(isPinned: boolean): string {
    return isPinned
      ? '../../../assets/images/unpinned-hover.png'
      : '../../../assets/images/pinned-hover.png';
  }

  onHoverPin(chat: any, state: boolean): void {
    chat.pinHovered = state;
  }

  convertToLocalDateTime(utcDate: string): Date {
    // Parse the UTC date string
    const utcDateObj = new Date(utcDate);

    // Get the local time offset (in minutes)
    const timeZoneOffset = utcDateObj.getTimezoneOffset();
    // Convert UTC time to local time
    return new Date(utcDateObj.getTime() - timeZoneOffset * 60000);
  }


  commentList: any[] = []
  totalComments: any = 0;
  commentPage: any = 1;
  commentSize: any = 10;
  commentsLoader = false;

  async getComments() {
    this.commentsLoader = true
    let payload = {
      size: this.commentSize,
      page: this.commentPage === 0 ? 0 : this.commentPage - 1,
    }
    await lastValueFrom(this.commentsService.getCommentsByDocument(payload, this.selectedItemToShow.id)).then((res: any) => {
      this.commentsLoader = false
      res.data.content.forEach((comment: any) => {
        comment.hoveredPin = false
        comment.formatedDate = this.formatTimeAgo(this.convertToLocalDateTime(comment.createdAt).toString())
      })
      this.commentList = res.data.content
      this.totalComments = res.data.totalItems
    }).catch((error) => {
      this.commentsLoader = false
    })
  }

  commentSpinner = false;
  comment = '';
  addComment() {
    this.commentSpinner = true;
    let payload = {
      text: this.comment
    }
    lastValueFrom(this.commentsService.addCommentAgainstDocument(payload, this.selectedItemToShow.id)).then((res) => {
      this.commentSpinner = false;
      this.comment = ''
      this.getComments()
    }).catch((error) => {
      this.commentSpinner = false;
      console.log(error)
    })
  }

  formatTimeAgo(dateString: string): string {
    const now = new Date();
    const date = new Date(dateString);
    const diffMs = now.getTime() - date.getTime();

    const seconds = Math.floor(diffMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return `${seconds} seconds ago`;
    if (minutes < 60) return `${minutes} minutes ago`;
    if (hours < 24) return `${hours} hours ago`;
    if (days < 7) return `${days} days ago`;
    if (weeks < 4) return `${weeks} weeks ago`;
    if (months < 12) return `${months} months ago`;
    return `${years} years ago`;
  }

  updateCommentPin(chat: any) {
    lastValueFrom(this.commentsService.pinUnpinComment(chat.id, chat.isPinned ? 'unpin' : 'pin')).then((res) => {
      this.getComments()
    })
  }
  // async nextDoc() {
  //   await this.setSectionAsCompleted(this.selectedItemToShow);
  //   this.quizQuestions = {}

  //   for (let index = 0; index < this.courseDetails.length; index++) {
  //     const item = this.courseDetails[index];

  //     for (let docIndex = 0; docIndex < item.documents.length; docIndex++) {
  //       const doc = item.documents[docIndex];
  //       // console.log('Current Document:', doc);
  //       // console.log('Selected Item to Show:', this.selectedItemToShow);

  //       // Compare documents based on a unique property (e.g., doc.id or doc.name)
  //       if (this.selectedItemToShow && this.selectedItemToShow.id === doc.id) {
  //         if (docIndex < item.documents.length - 1) {
  //           // If there are more documents in the current item, select the next one
  //           this.selectedItemToShow = item.documents[docIndex + 1];
  //           // console.log('Next document selected:', this.selectedItemToShow);
  //           // if (this.selectedItemToShow.documentType === 'QUIZ') {
  //           this.selectSession(this.selectedItemToShow)
  //           // }
  //           return;  // Exit after setting the next document
  //         } else if (index < this.courseDetails.length - 1 && this.courseDetails[index + 1].documents.length > 0) {
  //           // If the current document is the last one, switch to the next courseDetail's first document
  //           this.selectedItemToShow = this.courseDetails[index + 1].documents[0];
  //           // console.log('Switched to next course\'s first document:', this.selectedItemToShow);
  //           return;  // Exit after switching to the next course's first document
  //         } else {
  //           // Optional: Handle case when there are no more courseDetails left
  //           if (this.courseDetails[this.courseDetails.length - 1].completed) {
  //             Swal.fire('Congratulations', 'You have completed the course', 'success');
  //             this.router.navigate(['/main/learner/completed-courses']);
  //           }
  //           console.log('No more course details available');
  //           return;  // Exit after reaching the last document in the last course
  //         }
  //       }
  //     }
  //   }
  // }


  async completedMarkedCourse() {
    this.showSurvey = true
    // await this.setSectionAsCompleted(this.selectedItemToShow);
    // Swal.fire('Congratulations', 'You have completed the course', 'success');
    // this.router.navigate(['/main/learner/completed-courses']);

  }

  async nextDoc() {
    if (this.role === 'USER') {
      await this.setSectionAsCompleted(this.selectedItemToShow);
    }
    this.quizQuestions = {};
    let completed = false;

    if (completed) {
      Swal.fire('Congratulations', 'You have completed the course', 'success');
      this.router.navigate(['/main/learner/completed-courses']);
    }


    for (let index = 0; index < this.courseDetails.length; index++) {
      const item = this.courseDetails[index];

      for (let docIndex = 0; docIndex < item.documents.length; docIndex++) {
        const doc = item.documents[docIndex];

        // Compare documents based on a unique property (e.g., doc.id or doc.name)
        if (this.selectedItemToShow && this.selectedItemToShow.id === doc.id) {
          if (docIndex < item.documents.length - 1) {
            // If there are more documents in the current item, select the next one
            this.selectedItemToShow = item.documents[docIndex + 1];
            this.selectSession(this.selectedItemToShow);
            return;  // Exit after setting the next document
          } else if (index < this.courseDetails.length - 1 && this.courseDetails[index + 1].documents.length > 0) {
            // If the current document is the last one, switch to the next courseDetail's first document
            this.selectedItemToShow = this.courseDetails[index + 1].documents[0];
            return;  // Exit after switching to the next course's first document
          } else {
            // Check if all courseDetails and their documents are marked as completed
            const allCompleted = this.courseDetails.every(course =>
              course.documents.every((doc: any) => doc.completed)
            );
            console.log('All course details completed:', allCompleted);

            if (allCompleted) {
              Swal.fire('Congratulations', 'You have completed the course', 'success');
              this.router.navigate(['/main/learner/completed-courses']);
            } else {
              console.log('No more course details available');
            }
            return;  // Exit after reaching the last document in the last course
          }
        }
      }
    }
    this.courseDetails.forEach((course: any) => {
      if (course.completed) {
        completed = true
      }
      else {
        completed = false
      }
    })
    if (completed) {
      Swal.fire('Congratulations', 'You have completed the course', 'success');
      this.router.navigate(['/main/learner/completed-courses']);
    }
  }

  hasQuizResult(): boolean {
    return this.quizResult && Object.keys(this.quizResult).length > 0;
  }

  hasquizQuestions(): boolean {
    return this.quizQuestions && Object.keys(this.quizQuestions).length > 0;
  }

  prevDoc() {
    this.quizQuestions = {}
    if (this.showSurvey) {
      this.showSurvey = false
      return
    }
    for (let index = 0; index < this.courseDetails.length; index++) {
      const item = this.courseDetails[index];

      for (let docIndex = 0; docIndex < item.documents.length; docIndex++) {
        const doc = item.documents[docIndex];

        if (this.selectedItemToShow === doc) {
          if (docIndex > 0) {
            // If there are previous documents in the current item, select the previous one
            this.selectedItemToShow = item.documents[docIndex - 1];
            return;  // Exit after setting the previous document
          } else if (index > 0 && this.courseDetails[index - 1].documents.length > 0) {
            // If the current document is the first one, switch to the previous courseDetail's last document
            const previousCourse = this.courseDetails[index - 1];
            this.selectedItemToShow = previousCourse.documents[previousCourse.documents.length - 1];
            return;  // Exit after switching to the previous course's last document
          } else {
            // Optional: Handle case when there are no more previous courseDetails/documents
            console.log('No more previous documents available');
            return;  // Exit after reaching the first document
          }
        }
      }
    }
  }
  async setSectionAsCompleted(item: any) {
    let flag = false
    this.courseDetails.forEach((course: any) => {
      if (course.documents.includes(item)) {
        if (course.completed) {
          flag = true
        }
      }
    })
    this.ngxspinner.show()
    if (flag) {
      this.ngxspinner.hide()
      return
    }

    await lastValueFrom(this.Service.completeSession(item.id)).then((res) => {
      item.isCompleted = true
      this.ngxspinner.hide()
      lastValueFrom(this.Service.completeProgramCourse(this.courseId)).then((res) => {
        this.courseDetails.forEach((course: any) => {
          if (course.documents.includes(item)) {
            if (item.completed) {
              item.completed = true
            }
          }
          if (course.documents[course.documents.length - 1]?.completed) {
            course.completed = true
          }
        })
      })
      this.Service.getdocumentsEnrolled(this.courseId).subscribe(
        (res) => {
          if (res) {
            // 
            this.ngxspinner.hide()
            this.courseDetails = res.data

          } else {
            Swal.fire('No data found', 'Please wait for data to be added', 'info')
            this.router.navigate(['/main'])
            this.ngxspinner.hide()
          }
        },
        (error) => {
          this.ngxspinner.hide()
          Swal.fire('No data found', 'Please wait for data to be added', 'info')
          this.router.navigate(['/main'])

        }
      );

      // this.courseDetails.forEach((course: any) => {
      //   if (course.documents.includes(item)) {
      //     course.completed = true
      //   }
      // })

    }, err => {
      Swal.fire('Error', 'Please try again later', 'error')
      this.ngxspinner.hide()
    })
  }

  completeProgramCourse() {
  }
  redirectToCertificate() {
    this.router.navigate(['/main/learner/completed-courses'])
  }
  async setSelectDocument(item: any, document: any) {
    await this.getSectionDocument(item, document)
  }
  async getSectionDocument(item: any, document: any) {
    this.ngxspinner.show()

    await lastValueFrom(this.Service.getDocumentSectionById(item.id)).then(async (res) => {

      item.documentsArray = res.data
      this.selectedItemToShow = item.documentsArray.filter((doc: any) => doc.documentName === document.documentName)[0]
      console.log(this.selectedItemToShow)
      this.ngxspinner.hide()
    }, err => {
      Swal.fire('No data found', 'Please wait for the section to available', 'info')
    })
  }

  next() {
    this.courseDetails.forEach((item: any, index: any) => {


      item.documentsArray.forEach((doc: any, docIndex: any) => {
        if (this.selectedItemToShow === doc) {
          if (docIndex < item.documentsArray.length - 1) {
            this.selectedItemToShow = item.documentsArray[docIndex + 1]
          }
        }
      })
    })
  }
  previous() {
  }

  getLastCourseDetail() {
    if (this.courseDetails.length !== 0) {
      const lastCourse = this.courseDetails[this.courseDetails.length - 1];
      // if (lastCourse && lastCourse.documents && lastCourse.documents.length) {
      //   const lastDocument = lastCourse.documents[lastCourse.documents.length - 1];
      //   return lastDocument;
      // }
      return lastCourse;
    }
  }

  isLastDocument(): boolean {
    // Find the current document's course and document index
    for (let index = 0; index < this.courseDetails.length; index++) {
      const item = this.courseDetails[index];

      for (let docIndex = 0; docIndex < item.documents.length; docIndex++) {
        const doc = item.documents[docIndex];

        // If the current document is selectedItemToShow
        if (this.selectedItemToShow && this.selectedItemToShow.id === doc.id) {
          // Check if it's the last document of the course
          if (docIndex === item.documents.length - 1 && index === this.courseDetails.length - 1 && this.courseDetails.length === this.courseDetails[0].totalSections) {
            return true; // It's the last document of the last course
          }
        }
      }
    }
    return false; // Not the last document
  }

  quizForm = new FormGroup({
    textAnswer: new FormControl('', Validators.required)
  })


  drop(event: CdkDragDrop<any[]>, containerIndex?: number) {
    if (event.previousContainer !== event.container) {
      // If moving from bottom list to one of the top containers
      if (containerIndex !== undefined) {
        // Clear the current container if it already has an item
        if (event.container.data.length > 0) {
          event.container.data.pop();
        }
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          0 // Always insert at index 0 since we want one item per container
        );
      } else {
        // Moving from top container back to the bottom list
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    }
  }


  async onSubmit() {
    const fd = new FormData();

    // Append user answers to FormData based on questions
    this.quizQuestions.questions.forEach((question: any, index: any) => {

      this.analytics.logEvent('button_click', { button_name: 'Quiz Submitted' });
      fd.append(`userAnswers[${index}].questionId`, question.id);

      if (question.questionType === 'TEXT') {
        fd.append(`userAnswers[${index}].textAnswer`, this.quizForm.controls['textAnswer'].value || '');
      } else {
        const selectedOptionIds = this.selectedAnswers[question.id] || [];
        selectedOptionIds.forEach(optionId => {
          fd.append(`userAnswers[${index}].optionIds[]`, optionId); // Append multiple optionIds
        });
      }
    });

    let questions: any[] = []
    this.quizQuestions.questions.forEach((question: any) => {
      if (question.questionType === 'TEXT') {
        questions.push({
          questionId: question.id,
          textAnswer: this.quizForm.controls['textAnswer'].value || '',
        });
      } else if (question.questionType === 'DRAG_AND_DROP_SEQUENCE') {
        let answerOptions: any = []
        question.targetOptions.forEach((option: any, index: any) => {
          answerOptions.push({
            tempOptionId: option.tempOptionId,
            sequenceOrderNumber: index + 1
          })
        })
        questions.push({
          questionId: question.id,
          optionIds: question.targetOptions.map((x: any) => x.id) || [],
          answerOptions: answerOptions
        })
      } else if (question.questionType === 'DRAG_AND_DROP_MATRIX') {
        let answerOptions: any = []
        question.cellData.forEach((option: any, row: any) => {
          option.forEach((cell: any, col: any) => {
            if (cell.id) {
              answerOptions.push({
                tempOptionId: cell.tempOptionId,
                rowIndex: row + 1,
                columnIndex: col + 1
              })
            }
          })
        })


        questions.push({
          questionId: question.id,
          optionIds: question.options.map((x: any) => x.id) || [],
          answerOptions: answerOptions
        })
      } else if (question.questionType === 'DRAG_AND_DROP_EQUATION') {
        let answerOptions: any = []
        question.equationElements.forEach((element: any, index: any) => {
          if (element.expectedOptionId?.length > 0) {

            answerOptions.push({
              tempOptionId: element.tempOptionId,
              position: index + 1
            })
          }
        })


        questions.push({
          questionId: question.id,
          optionIds: question.options.map((x: any) => x.id) || [],
          answerOptions: answerOptions
        })
      } else {

        questions.push({
          questionId: question.id,
          optionIds: this.selectedAnswers[question.id] || [],
        })
      }
    })

    let payload = {
      userAnswers: questions
    }
    this.ngxspinner.show()
    try {
      const res = await lastValueFrom(this.Service.submitQuiz(this.quizId, payload));
      if (res) {
        this.quizResult = res.data
        this.nextDoc()
      }
      this.ngxspinner.hide()
      Swal.fire({
        title: 'Success',
        text: 'Quiz Submitted',
        icon: 'success',
        confirmButtonColor: '#198FD9',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      this.ngxspinner.hide()
      console.error('Error submitting quiz:', error);
      // Handle error if needed
    }
  }
  @ViewChildren('matrixDropList') matrixDropLists!: any;
  // Drop handler for placing items into grid cells
  dropItemIntoGrid(event: CdkDragDrop<any>, rowIndex: number, colIndex: number, question: any) {
    if (event.previousContainer === event.container) return;

    const item = event.previousContainer.data[event.previousIndex];
    question.targetOptions[rowIndex][colIndex] = item;
    event.previousContainer.data.splice(event.previousIndex, 1);
  }

  connectedDropLists: string[] = ['optionsList'];
  ngAfterViewInit() {
    // Update the connectedDropLists array after matrixDropLists is initialized
    this.matrixDropLists.forEach((_: any, index: any) => {
      this.connectedDropLists.push(`matrixDropList_${index}`);
    });
  }

}