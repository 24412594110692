import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { PathwayFormService } from 'src/app/portal/pathway-management/pathway-form/pathway-form.service';

@Component({
  selector: 'app-assign-pathway',
  templateUrl: './assign-pathway.component.html',
  styleUrls: ['./assign-pathway.component.scss']
})
export class AssignPathwayComponent {
  courses: any[] = []

  constructor(private activeModal: NgbActiveModal, private service: PathwayFormService) {
  }

  ngOnInit() {
  }
  close() {
    this.activeModal.close()
  }
  save() {
    let list = this.courses.filter(course => course.active)
    this.activeModal.close(list)
  }
  clearAll() {
    this.courses.forEach(course => course.active = false)
  }
}

